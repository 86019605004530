class TimeUtils {
    nowSeconds(): number {
        return Math.floor(Date.now() / 1000);
    }

    toSeconds(date:Date): number {
        return Math.floor(date.getTime() / 1000);
    }
}

export const timeUtils = new TimeUtils();

