
import {Component, Prop, Vue} from "vue-property-decorator";
import {couponUtils} from "@/lib/couponUtils";
import {CouponProps} from "@/components/coupon/type/CouponProps";
import {dateUtils} from "@/lib/dateUtils";
import {numberUtils} from "@/lib/numberUtils";
import {phoneUtils} from "@/lib/phoneUtils";

@Component
export default class CouponInfo extends Vue {
  @Prop() readonly coupon!: CouponProps;
  @Prop() readonly consuming!: boolean;
  today= dateUtils.formatToday() ;

  mounted() {
    console.log(this.coupon);
  }

  get classCouponAttrKey(): string {
    return "body-1 text--secondary text-end pa-1 ma-0";
  }

  get classCouponAttrValue(): string {
    return "body-1 pa-1 px-3 ma-0";
  }

  get couponAttrKeyCols(): string {
    return "3";
  }

  get couponAttrValueCols(): string {
    return "9";
  }

  get couponNumber(): string {
    if (couponUtils.isBookingCoupon(this.coupon.goodsType)) {
      return this.coupon.itemNo + " (예약권)"
    } else {
      return this.coupon.itemNo;
    }
  }

  get couponStatus(): string {
    return couponUtils.couponStatusCaption(this.coupon);
  }

  get isAvailableCoupon(): boolean {
    return couponUtils.isAvailableCoupon(this.coupon.itemStatus);
  }

  get isMobileCoupon(): boolean {
    return couponUtils.isMobileCoupon(this.coupon.goodsType);
  }

  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.coupon.goodsType);
  }

  get couponTypeCaption(): string {
    return couponUtils.goodsTypeCaption(this.coupon.goodsType);
  }

  get consumedDate(): string {
    return dateUtils.formatDate(this.coupon.consumedDate);
  }

  get usableAmount(): string {
    return numberUtils.formatNumber(this.coupon.usableAmount);
  }

  get receiverPhone(): string {
    return phoneUtils.formatPhoneNumber(this.coupon.receiverPhone);
  }
}
