class NumberUtils {
    formatNumber(num:number|string|null|undefined): string {
        if (num) {
            return Number(num).toLocaleString();
        } else {
            return "0";
        }
        // return new Intl.NumberFormat('en-US').format(num);
    }
}

export const numberUtils = new NumberUtils();
