import {OrderItemDto} from "@/api/service/dto/order/couponListDto";

export interface CouponProps extends OrderItemDto{
    orderNo: string;
    // itemNo: string;
    // itemStatus: string;
    // goodsType: string;
    // goodsName: string;
    // optionName: string;
    // receiverName: string;
    // receiverPhone: string;
    // usableAmount?: string;
    // availableFrom?: string;
    // availableTo?: string;
    // consumedDate?: string;
}

export class CouponPropsUtil {
    static toCouponPropsFromOrderItemDto(dto: OrderItemDto, orderNo: string): CouponProps {
        return {
            orderNo,
            itemNo: dto.itemNo,
            itemStatus: dto.itemStatus,
            goodsType: dto.goodsType,
            goodsId: dto.goodsId,
            goodsName: dto.goodsName,
            goodsTag: dto.goodsTag,
            optionId: dto.optionId,
            optionName: dto.optionName,
            issueDate: dto.issueDate,
            availableFrom: dto.availableFrom,
            availableTo: dto.availableTo,
            cancellable: dto.cancellable,
            cancellableTo: dto.cancellableTo,
            receiverName: dto.receiverName,
            receiverPhone: dto.receiverPhone,
            consumedDate: dto.consumedDate,
            usableAmount: dto.usableAmount
        };
    }
}
