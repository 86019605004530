
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {dateUtils} from "@/lib/dateUtils";
import {phoneUtils} from "@/lib/phoneUtils";
import {bookingUtils} from "@/lib/bookingUtils";
import {bookingHeadcountStr} from "@/components/booking/type/BookingProps";
import {BookingDetailsDto} from "@/api/service/dto/order/bookingDetailsDto";
import {BookingTypeEnum} from "@/api/service/type/bookingTypeEnum";

@Component
export default class BookingInfo extends Vue {
  @Prop() readonly booking!: BookingDetailsDto;
  @Prop() readonly enableCheckbox!: boolean;

  mounted(): void {
    this.$nextTick(() => {
      this.onChangeItemCheck();
    });
  }

  get classBookingAttrKey(): string {
    return "body-1 text--secondary text-end pa-1 ma-0";
  }

  get classBookingAttrValue(): string {
    return "body-1 pa-1 ma-0 px-3";
  }

  get bookingAttrKeyCols(): string {
    return "3";
  }

  get bookingAttrValueCols(): string {
    return "9";
  }

  get bookingNumber(): string {
    if (this.booking.bookingType === BookingTypeEnum.ManualBooking) {
      return this.booking.bookingNo + " (수기예약)";
    } else {
      return this.booking.bookingNo;
    }
  }

  get isBookingRequest(): boolean {
    return bookingUtils.isBookingRequest(this.booking.bookingStatus);
  }

  get bookingStatusCaption(): string {
    return bookingUtils.bookingStatusCaption(this.booking.bookingStatus, this.booking.noShow);
  }

  get requestedDateTime(): string {
    return dateUtils.formatDateTime(this.booking.bookingRequestDate);
  }

  get reservationDateTime(): string {
    return dateUtils.formatDateTime(this.booking.reservationDate);
  }

  get visitDateTime(): string {
    return dateUtils.formatDateTime(this.booking.visitDate);
  }

  get visitorPhone(): string {
    return phoneUtils.formatPhoneNumber(this.booking.visitorPhone);
  }

  get headcountStr(): string {
    return bookingHeadcountStr(this.booking);
  }

  depositBookingAmount(): number {
    let amount = 0;

    this.booking.orderItems.forEach(item => {
      if (this.booking.depositBooking && item.checked) {
        amount += parseInt(item.depositAmount, 10);
      }
    });

    return amount;
  }

  onChangeItemCheck(): void {
    if (this.booking.depositBooking) {
      this.onChangeDepositAmount();
    }
  }

  @Emit()
  onChangeDepositAmount(): number {
    return this.depositBookingAmount();
  }
}
