export const apiConstants = {
    apiServerURL: process.env.NODE_ENV === 'production'
        ? 'https://api.tablenjoy.com'
        : 'https://renewal-api.tablenjoy.com:30080',

    auth: {
        register: { endpoint: '/users/v1/{site_name}/admin/auth/register', requiresToken: false},
        login: { endpoint: '/users/v1/{site_name}/admin/auth/login', requiresToken: false},
        refresh: { endpoint: '/users/v1/{site_name}/admin/auth/refresh', requiresToken: false},
        logout: { endpoint: '/users/v1/{site_name}/admin/logout', requiresToken: true},
        myInfo: { endpoint: '/users/v1/{site_name}/admin/myInfo', requiresToken: true},
    },
    goods: {
        page: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/page', requiresToken: true},                                             // 상품 리스트 조회
        intro: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/intro/query', requiresToken: true},                                     // 상품 소개 정보 조회 (Manager)
        options: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/options/query', requiresToken: true},                                 // 상품 옵션 조회 (Manager)
        condition: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/condition/query', requiresToken: true},                             // 상품 사용 조건 조회 (Manager)
        optionsAndUsableHours: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/optionsAndUsableHours/query', requiresToken: true},     // 상품 옵션 및 이용 가능 시간 조회 (Manager)
        channels: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/channels/query', requiresToken: true},                               // 상품 B2B 채널 배포 설정 조회 (Manager)
        usage: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/usage/query', requiresToken: true},                                     // 상품 사용 정보 조회 (Manager)
        notice: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/notice/query', requiresToken: true},                                   // 상품 고시 정보 및 기타 설정 조회 (Manager)
        usableHours: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/usableHours/query', requiresToken: true},                         // 상품 이용 가능 시간 조회 (Manager)
        stopSales: { endpoint: '/goods/v1/{site_name}/mng/goods/operation/adm/goods/stopSales', requiresToken: true},                          // 상품 판매 중지 (Manager)
        resumeSales: { endpoint: '/goods/v1/{site_name}/mng/goods/operation/adm/goods/resumeSales', requiresToken: true},                       // 상품 판매 재개 (Manager)
        updateMainOption: { endpoint: '/goods/v1/{site_name}/mng/goods/operation/adm/options/mainOption/update', requiresToken: true},         // 메인 옵션 변경 (Manager)
        optionsStopSales: { endpoint: '/goods/v1/{site_name}/mng/goods/operation/adm/options/stopSales', requiresToken: true},                 // 옵션 판매 중지 (Manager)
        optionsResumeSales: { endpoint: '/goods/v1/{site_name}/mng/goods/operation/adm/options/resumeSales', requiresToken: true},             // 옵션 판매 재개 (Manager)
        create: { endpoint: '/goods/v1/{site_name}/mng/goods/register/adm/draft/create', requiresToken: true},                                  // 등록 대기 상품 정보 생성  (BRD)
        registerPage: { endpoint: '/goods/v1/{site_name}/mng/goods/register/adm/page', requiresToken: true},                                    // 등록 대기 상품 리스트 조회  (BRD)
        requestedPage: { endpoint: '/goods/v1/{site_name}/mng/goods/register/adm/requested/page', requiresToken: true},                        // 등록 승인 요청 상품 리스트 조회 (BRD, TNJ)
        info: { endpoint: '/goods/v1/{site_name}/mng/goods/register/adm/get', requiresToken: true},                                             // 등록 대기 상품 정보 조회  (BRD)
        update: { endpoint: '/goods/v1/{site_name}/mng/goods/register/adm/draft/update', requiresToken: true},                                  // 등록 대기 상품 정보 수정  (BRD)
        request: { endpoint: '/goods/v1/{site_name}/mng/goods/register/brd/request', requiresToken: true},                                      // 등록 대기 상품 등록 요청  (BRD)
        updateIntro: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/intro/update', requiresToken: true},                               //상품 소개 정보 변경 (Manager)
        updateCondition: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/condition/update', requiresToken: true},                       //상품 사용 조건 변경 (Manager)
        updateOptions: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/options/update', requiresToken: true},                            //상품 옵션 정보 변경 (Manager)
        updateUsableHours: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/usableHours/update', requiresToken: true},                  //상품 이용 가능 시간 변경 (Manager)
        updateOptionsAndUsableHours: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/optionsAndUsableHours/update', requiresToken: true}, //상품 옵션 및 이용 가능 시간 변경 (Manager)
        updateChannels: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/channels/update', requiresToken: true},                          //상품 B2B 채널 배포 설정 변경 (Manager)
        updateUsage: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/usage/update', requiresToken: true},                               //상품 사용 정보 변경 (Manager)
        updateNotice: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/notice/update', requiresToken: true},                             //상품 고시 정보 및 기타 설정 변경 (Manager)
        deleteGoods: { endpoint: '/goods/v1/{site_name}/mng/goods/manage/adm/goods/delete', requiresToken: true},                              //상품 삭제 (Manager)
        deleteReqGoods: { endpoint: '/goods/v1/{site_name}/mng/goods/register/adm/draft/delete', requiresToken: true},                         //등록 대기 상품 정보 삭제 (BRAND, TNJ)
        cancelRequest: { endpoint: '/goods/v1/{site_name}/mng/goods/register/brd/cancelRequest', requiresToken: true},                          //등록 대기 상품 등록 요청 취소 (BRAND, TNJ)
    },
    brand: { // 브랜드 API
        brandSiteList: { endpoint: '/brands/v1/{site_name}/public/brand/site/list', requiresToken: false},                              // 브랜드 사이트 리스트
        brandSiteInfo: { endpoint: '/brands/v1/{site_name}/public/brand/site/info', requiresToken: false},                              // 브랜드 사이트 정보
    },
    shop: { // 매장 API (관리자)
        area: { endpoint: '/brands/v1/{site_name}/public/store/storeAreas', requiresToken: true},
        delete: { endpoint: '/brands/v1/{site_name}/mng/store/adm/delete', requiresToken: true},                                                  // 매장삭제 (BRAND, TNJ)
        activate: { endpoint: '/brands/v1/{site_name}/mng/store/adm/enable', requiresToken: true},                                              // 매장 활성화 (BRAND, TNJ)
        deactivate: { endpoint: '/brands/v1/{site_name}/mng/store/adm/disable', requiresToken: true},                                          // 매장 비활성화 (BRAND, TNJ)
        requestList: { endpoint: '/brands/v1/{site_name}/mng/store/register/brd/newStore/pending/list', requiresToken: true},                      // 매장 등록 요청 리스트 조회
        registerInfo: { endpoint: '/brands/v1/{site_name}/mng/store/register/adm/newStore/register/info', requiresToken: true},                   // 작업 중인 매장 등록 정보 조회 (BRAND, TNJ)
        draftCreate: { endpoint: '/brands/v1/{site_name}/mng/store/register/brd/newStore/draft/create', requiresToken: true},                     // 매장 등록 정보 임시 저장 (신규 생성) (BRAND)
        draftDelete: { endpoint: '/brands/v1/{site_name}/mng/store/register/brd/newStore/draft/delete', requiresToken: true},                     // 매장 등록 정보 임시 저장 삭제 (BRAND)
        update: { endpoint: '/brands/v1/{site_name}/mng/store/register/brd/newStore/draft/update', requiresToken: true},                          // 매장 등록 정보 임시 저장 (기존 정보 수정) (BRAND)
        create: { endpoint: '/brands/v1/{site_name}/mng/store/register/brd/newStore/register/request', requiresToken: true},                       // 매장 등록 요청  (BRD)
        reqCancel: { endpoint: '/brands/v1/{site_name}/mng/store/register/brd/newStore/register/cancelRequest', requiresToken: true},             // 매장 등록 요청 취소 (BRAND)
        brandStoreCodeSearch: { endpoint: '/brands/v1/{site_name}/mng/store/brd/storeCodeNames/ofBrand', requiresToken: true},                    //브랜드 내 매장코드/매장명 리스트 검색 (BRD, STR)
        brandStoreCode: { endpoint: '/brands/v1/{site_name}/public/store/storeCodeNames/inSite', requiresToken: true},                             //사이트 내 매장코드/매장명 리스트 조회
        brandInfo : { endpoint: '/brands/v1/{site_name}/mng/brand/adm/brandsIdentities/bySites', requiresToken: true},                               //도메인 이름으로 브랜드 코드/이름 조회
        page: { endpoint: '/brands/v1/{site_name}/mng/store/adm/basicInfoStore/page', requiresToken: true},                                        // 매장 리스트 조회 (Manager)
        exists: { endpoint: '/brands/v1/{site_name}/mng/store/brd/storeName/exists', requiresToken: true},                                         // 매장 이름 중복 확인  (BRD)

        basicInfo: { endpoint: '/brands/v1/{site_name}/mng/store/str/basicInfo/query', requiresToken: true},                                       // 매장 기본 정보 조회 (BRD)
        basicInfoUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/basicInfo/update', requiresToken: true},                                // 매장 기본 정보 변경 (BRD)
        contents: { endpoint: '/brands/v1/{site_name}/mng/store/brd/siteContents/query', requiresToken: true},                                    // 매장 콘텐츠 정보 조회 (BRD)
        contentsUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/siteContents/update', requiresToken: true},                             // 매장 콘텐츠 정보 변경 (BRD)
        businessInfo: { endpoint: '/brands/v1/{site_name}/mng/store/brd/runningInfo/query', requiresToken: true},                                  // 매장 운영 정보 조회  (BRD)
        businessInfoUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/runningInfo/update', requiresToken: true},                           // 매장 운영 정보 변경  (BRD)
        bookingSettings: { endpoint: '/brands/v1/{site_name}/mng/store/brd/bookingSettings/query', requiresToken: true},                           // 매장 운영 예약 설정 조회  (BRD)
        bookingSettingsUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/bookingSettings/update', requiresToken: true},                    // 매장 운영 예약 설정 변경  (BRD)
        weekendSettings: { endpoint: '/brands/v1/{site_name}/mng/store/brd/weekendSettings/query', requiresToken: true},                           // 매장 운영 주말 설정 조회  (BRD)
        weekendSettingsUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/weekendSettingsUpdate', requiresToken: true},                     // 매장 운영 주말 설정 변경  (BRD)
        inventorySettingsList: { endpoint: '/brands/v1/{site_name}/mng/store/brd/inventorySettings/list', requiresToken: true},                     // 매장 예약 가능 인원 설정 리스트 조회  (BRD)
        inventorySettingsCreate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/inventorySettings/create', requiresToken: true},                 // 매장 예약 가능 인원 설정 정보 생성  (BRD)
        inventorySettingsPriorityDelete: { endpoint: '/brands/v1/{site_name}/mng/store/brd/inventorySettings/delete', requiresToken: true},          //예약 가능 인원 설정 정보 삭제 (BRD)
        inventorySettingsPriorityUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/inventorySettings/priority/update', requiresToken: true}, //매장 예약 가능 인원 설정 우선 순위 변경  (BRD)

        basicInfoStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/basicInfo/query', requiresToken: true},                                    // 매장 기본 정보 조회 (STR)
        basicInfoUpdateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/basicInfo/update', requiresToken: true},                                // 매장 기본 정보 변경 (STR)
        contentsStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/siteContents/query', requiresToken: true},                                    // 매장 콘텐츠 정보 조회  (STR)
        contentsUpdateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/siteContents/update', requiresToken: true},                             // 매장 콘텐츠 정보 변경  (STR)
        businessInfoStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/runningInfo/query', requiresToken: true},                                  // 매장 운영 정보 조회   (STR)
        businessInfoUpdateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/runningInfo/update', requiresToken: true},                           // 매장 운영 정보 변경   (STR)
        bookingSettingsStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/bookingSettings/query', requiresToken: true},                           // 매장 운영 예약 설정 조회   (STR)
        bookingSettingsUpdateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/bookingSettings/update', requiresToken: true},                    // 매장 운영 예약 설정 변경   (STR)
        weekendSettingsStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/weekendSettings/query', requiresToken: true},                           // 매장 운영 주말 설정 조회   (STR)
        weekendSettingsUpdateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/weekendSettingsUpdate', requiresToken: true},                     // 매장 운영 주말 설정 변경   (STR)
        inventorySettingsListStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/inventorySettings/list', requiresToken: true},                     // 매장 예약 가능 인원 설정 리스트 조회  (STR)
        inventorySettingsCreateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/inventorySettings/create', requiresToken: true},                 // 매장 예약 가능 인원 설정 정보 생성  (STR)
        inventorySettingsPriorityDeleteStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/inventorySettings/delete', requiresToken: true},          //예약 가능 인원 설정 정보 삭제 (STR)
        inventorySettingsPriorityUpdateStr: { endpoint: '/brands/v1/{site_name}/mng/store/str/inventorySettings/priority/update', requiresToken: true}, //매장 예약 가능 인원 설정 우선 순위 변경  (STR)

        inventorySettings: { endpoint: '/brands/v1/{site_name}/mng/store/brd/inventorySettings/query', requiresToken: true},                        // 매장 예약 가능 인원 설정 조회 (BRD, STR)
        inventorySettingsUpdate: { endpoint: '/brands/v1/{site_name}/mng/store/brd/inventorySettings/update', requiresToken: true},                 // 매장 예약 가능 인원 설정 정보 변경  (BRD, STR)

        bookingStatusMonthly: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/status/monthly', requiresToken: true},    // 예약 현황 조회 (월별)
        bookingStatusWeekly: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/status/weekly', requiresToken: true},      // 예약 현황 조회 (주별)
        bookingStatusDaily: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/status/daily', requiresToken: true},        // 예약 현황 조회 (일별)
        bookingDateStop: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/date/stop', requiresToken: true},              // 예약 중지 (특정일)
        bookingDateResume: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/date/resume', requiresToken: true},          // 예약 재개 (특정일)
        bookingTimeSlotStop: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/timeSlot/stop', requiresToken: true},      // 예약 중지 (특정 시간대)
        bookingTimeSlotResume: { endpoint: '/brands/v1/{site_name}/mng/store/str/booking/timeSlot/resume', requiresToken: true},  // 예약 재개 (특정 시간대)

    },
    user: { // 회원 및 관리자
        list: { endpoint: '/users/v1/{site_name}/admin/staff/list', requiresToken: true},
        info: { endpoint: '/users/v1/{site_name}/admin/staff/info', requiresToken: true},
        myInfo: { endpoint: '/users/v1/{site_name}/admin/myInfo', requiresToken: true},
        register: { endpoint: '/users/v1/{site_name}/admin/auth/register', requiresToken: false},                                                       // 어드민 회원가입
        changePassword: { endpoint: '/users/v1/{site_name}/admin/changePassword', requiresToken: true},                                                // 비밀번호변경
        changeInfo: { endpoint: '/users/v1/{site_name}/admin/changeInfo', requiresToken: true},                                                        // 회원정보변경
        findPassOtp : { endpoint: '/users/v1/{site_name}/admin/auth/findPassword/otp', requiresToken: false},                                          //비밀번호 찾기 임시 비밀번호 요청
        findPassLogin : { endpoint: '/users/v1/{site_name}/admin/auth/findPassword/login', requiresToken: false},                                      //비밀번호 찾기 임시 비밀번호 로그인
        // unregister: { endpoint: '/users/v1/{site_name}/admin/unregister', requiresToken: true},                                                     // 관리자 게정삭제
        unregister: { endpoint: '/users/v1/{site_name}/admin/staff/unregister', requiresToken: true},                                                  // 관리자 게정삭제
        // grantRole: { endpoint: '/users/v1/{site_name}/admin/grantRole', requiresToken: true},                                                       // 관리자 권한 부여
        grantRole: { endpoint: '/users/v1/{site_name}/admin/brd/staff/grantRole', requiresToken: true},                                                // 관리자 권한 부여
        azureSas: { endpoint: '/users/v1/{site_name}/admin/sas', requiresToken: true},                                                                 //애저 토큰

    },
    order: {
        // bookingList: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/bookingList', requiresToken: true},                              //예약 리스트 조회 ( 매장 + 브랜드 )
        bookingList: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/bookings/list', requiresToken: true},                               //예약 리스트 조회 ( 매장 + 브랜드 )
        // bookingRequestList: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/bookingRequestList', requiresToken: true},                //예약 신청 리스트 조회 ( 매장 + 브랜드 )
        bookingRequestList: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/bookingRequests/list', requiresToken: true},                 //예약 신청 리스트 조회 ( 매장 + 브랜드 )
        bookingDetail: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/bookingDetails', requiresToken: true},                            //예약 내용 상세 조회 ( 매장 + 브랜드 )
        approveBookingRequest: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/approveBookingRequest', requiresToken: true},                   //예약 신청 승인(매장운영자)
        rejectBookingRequest: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/rejectBookingRequest', requiresToken: true},                     //예약 신청 거절(매장운영자)
        cancelBooking: { endpoint: '/orders/v1/{site_name}/mng/booking/brd/cancelBooking', requiresToken: true},                                   //예약취소 ( 매장 + 브랜드 )
        noShow: { endpoint: '/orders/v1/{site_name}/mng/booking/brd/noShow', requiresToken: true},                                                 //예약 노쇼 처리
        // cancelNoShow: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/cancelNoShow', requiresToken: true},                                    //예약 노쇼 처리 취소
        visitStore: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/visitStore', requiresToken: true},                                         //예약방문처리 ( 매장운영자)
        storeVisitInfo: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/storeVisitInfo', requiresToken: true},                           //예약 방문 처리 내역 조회 ( 매장 + 브랜드 )
        cancelVisitStore: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/cancelVisitStore', requiresToken: true},                             //예약 방문처리 취소 ( 매장운영자)
        // orderList: { endpoint: '/orders/v1/{site_name}/mng/order/adm/query/orderList', requiresToken: true},                                    // 주문내역조회 ( 매장 + 브랜드 )
        orderList: { endpoint: '/orders/v1/{site_name}/mng/order/adm/query/orders/page', requiresToken: true},                                     // 주문내역조회 ( 매장 + 브랜드 )
        orderDetails: { endpoint: '/orders/v1/{site_name}/mng/order/adm/query/orderDetails', requiresToken: true},                                 // 주문상세 조회( 매장 + 브랜드 )
        extendExpiryDate: { endpoint: '/orders/v1/{site_name}/mng/order/adm/extendExpiryDate', requiresToken: true},                               // 유효기간연장 ( 매장 + 브랜드 )
        cancelOrder: { endpoint: '/orders/v1/{site_name}/mng/order/brd/requestRefund', requiresToken: true},                                      // 주문 취소 (BRAND)
        saveMemo: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/saveMemo', requiresToken: true},                                            // 예약관련메모 저장
        orderSaveMemo: { endpoint: '/orders/v1/{site_name}/mng/order/adm/saveMemo', requiresToken: true},                                         // 주문관련메모 저장
        bookingCancelRefundAmount: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/refundAmount', requiresToken: true},
        noShowRefundAmount: { endpoint: '/orders/v1/{site_name}/mng/booking/adm/query/noShowRefundAmount', requiresToken: true},
    },
    coupon: {
        couponList: { endpoint: '/orders/v1/{site_name}/mng/order/brd/item/query/couponList', requiresToken: true}, // 모바일쿠폰, 금액권 쿠폰 조회
        consumeMobileCoupons: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/consumeMobileCoupons', requiresToken: true}, // 모바일쿠폰 사용처리
        consumeBookingCoupons: { endpoint: '/orders/v1/{site_name}/mng/order/str/item/consumeBookingCoupons', requiresToken: true}, // 예약권 사용처리
        useMoneyCoupon: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/useMoneyCoupon', requiresToken: true}, // 금액권 사용처리
        moneyCouponAmount: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/query/moneyCouponAmount', requiresToken: true}, // 금액권 사용 가능 금액 조회
        cancelItemUseTransaction: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/cancelItemUseTransaction', requiresToken: true}, // 모바일 쿠폰, 금액권 사용처리 취소
        itemUseInfo: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/query/itemUseInfo', requiresToken: true}, // 모바일 쿠폰, 금액권 사용처리 내용 조회
        itemsUseInfo: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/query/itemsUseInfo', requiresToken: true}, // 모바일 쿠폰, 금액권 리스트 사용처리 내용 조회
        reissueItems: { endpoint: '/orders/v1/{site_name}/mng/order/adm/item/reissueItems', requiresToken: true}, // 모바일 쿠폰, 금액권 재발급
    },
    sales: {
        settlements : { endpoint: '/orders/v1/{site_name}/mng/settlement/brd/stores/settlements/totalize', requiresToken: true},                //정산 금액 집계 (BRD)
        settlementsStr : { endpoint: '/orders/v1/{site_name}/mng/settlement/brd/store/settlements/totalize', requiresToken: true},              //정산 금액 집계 (STR)
        completedSettlements : { endpoint: '/orders/v1/{site_name}/mng/settlement/brd/stores/completedSettlements/query', requiresToken: true},//정산완료(입금완료) 내역 조회 (BRD)
        completedSettlementsStr : { endpoint: '/orders/v1/{site_name}/mng/settlement/str/stores/completedSettlements/query', requiresToken: true},//정산완료(입금완료) 내역 조회 (STR)
    },
    statistics: {
        completedSettlementsReport : { endpoint: '/orders/v1/{site_name}/mng/settlement/brd/stores/completedSettlements/statistics/query', requiresToken: true},  //브랜드 월별 정산내역 통계
        completedSettlementsReportStr : { endpoint: '/orders/v1/{site_name}/mng/settlement/str/stores/completedSettlements/statistics/query', requiresToken: true}        //매장 월별 정산내역 통계
    },
    b2b: {
        channelList: { endpoint: '/b2b/v1/{site_name}/public/channelList', requiresToken: true}                   //B2B 공용 API (Public)
    }
};
