import {ApiResponse, httpClient} from "@/api/httpClient";
import {apiConstants} from "@/api/apiConstants";
import {BookingRequestInfoDto} from "@/api/service/dto/order/bookingRequestListDto";
import {
    BookingDateTypeEnum,
    BookingInfoDto,
    BookingListKeywordTypeEnum,
    BookingListRequestDto
} from "@/api/service/dto/order/bookingListDto";
import {BookingDetailsRequestDto, BookingDetailsResponseDto} from "@/api/service/dto/order/bookingDetailsDto";
import {StoreVisitInfoDto} from "@/api/service/dto/order/storeVisitInfoDto";
import {NoShowRefundAmountDto} from "@/api/service/dto/order/noShowRefundAmountDto";
import {BookingCancelRefundAmountDto} from "@/api/service/dto/order/bookingCancelRefundAmountDto";

// export enum BookingDateType {
//     RequestTime = "RequestTime", VisitDate = "VisitDate"
// }

// export enum BookingKeywordType {
//     NoKeyword = "NoKeyword", BookingNo = "BookingNo", PhoneNumber = "PhoneNumber", PhoneNumber4 = "PhoneNumber4"
// }

class BookingApi {
    searchBookingRequests(): Promise<ApiResponse<BookingRequestInfoDto[]>> {
        return httpClient.httpGet(apiConstants.order.bookingRequestList);
    }

    searchBookings(dateType: BookingDateTypeEnum, dateFrom: string, dateTo: string,
                   keywordType: BookingListKeywordTypeEnum, keyword:string): Promise<ApiResponse<BookingInfoDto[]>> {
        const request: BookingListRequestDto = {
            dateType,
            dateFrom,
            dateTo,
            keywordType,
            keyword
        }

        return httpClient.httpPost(apiConstants.order.bookingList, request);
    }

    queryBookingDetails(bookingNo: string): Promise<ApiResponse<BookingDetailsResponseDto>> {
        const request: BookingDetailsRequestDto = {
            bookingNo
        };

        return httpClient.httpPost(apiConstants.order.bookingDetail, request)
    }

    rejectBookingRequest(bookingNo: string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.order.rejectBookingRequest, {
            bookingNo: bookingNo
        })
    }

    acceptBookingRequest(bookingNo: string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.order.approveBookingRequest, {
            bookingNos: [bookingNo]
        })
    }

    visitStore(bookingNo:string, orderItems:string[], noPenalty): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.order.visitStore, {
            bookingNo: bookingNo,
            orderItems: orderItems,
            noPenalty
        })
    }

    noShow(bookingNo:string, noPenalty: boolean , type:any, reasonType:any): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.order.noShow, {
            bookingNo: bookingNo,
            noPenalty,
            type,
            reasonType
        })
    }

    cancelBooking(bookingNo: string, noPenalty: boolean, type:any, reasonType:any): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.order.cancelBooking, {
            bookingNo: bookingNo,
            noPenalty,
            type,
            reasonType
        })
    }

    queryVisitInfo(bookingNo: string): Promise<ApiResponse<StoreVisitInfoDto>> {
        return httpClient.httpPost(apiConstants.order.storeVisitInfo, {
            bookingNo: bookingNo
        })
    }

    cancelVisit(bookingNo: string, transactionNo:string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.order.cancelVisitStore, {
            bookingNo: bookingNo,
            transactionNo: transactionNo
        })
    }

    bookingCancelRefundAmount(bookingNo: string): Promise<ApiResponse<BookingCancelRefundAmountDto>> {
        return httpClient.httpPost(apiConstants.order.bookingCancelRefundAmount, {
            bookingNo: bookingNo
        })
    }

    noShowRefundAmount(bookingNo: string): Promise<ApiResponse<NoShowRefundAmountDto>> {
        return httpClient.httpPost(apiConstants.order.noShowRefundAmount, {
            bookingNo: bookingNo
        })
    }

    // cancelNoShow(bookingNo: string, transactionNo:string): Promise<ApiResponse> {
    //     return httpClient.httpPost(apiConstants.order.cancelNoShow, {
    //         bookingNo: bookingNo,
    //         transactionNo: transactionNo
    //     })
    // }
}

export const bookingApi = new BookingApi();
