import {CouponUseTransactionDto} from "@/api/service/dto/order/couponUseInfoDto";
import {GoodsTypeEnum} from "@/api/service/type/goodsTypeEnum";

export interface CouponTransactionProps extends CouponUseTransactionDto{
    itemNo: string;
    goodsType: GoodsTypeEnum;

    // transactionNo:string;
    // brandName:string;
    // brandCode:string;
    // storeName:string;
    // storeCode:string;
    // useDate:string;
    // useAmount:string;
    // currencyCode:string;
}

export class CouponTransactionPropsUtil {
    static toTransactionPropsFromDto(dto: CouponUseTransactionDto, itemNo: string, goodsType: GoodsTypeEnum): CouponTransactionProps {
        return {
            itemNo,
            goodsType,
            transactionNo: dto.transactionNo,
            brandName: dto.brandName,
            brandCode: dto.brandCode,
            storeName: dto.storeName,
            storeCode: dto.storeCode,
            useDate: dto.useDate,
            useAmount: dto.useAmount,
            currencyCode: dto.currencyCode,
            siteName: dto.siteName,
            managerId: dto.managerId,
            cancelled: dto.cancelled,
            cancelledDate: dto.cancelledDate
        };
    }
}
