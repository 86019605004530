import {BookingStatusEnum} from "@/api/service/type/bookingStatusEnum";

class BookingUtils {
    bookingStatusCaption(bookingStatus:BookingStatusEnum, noShow?:boolean): string {
        if (bookingStatus === BookingStatusEnum.BookingRequested) {
            return "예약신청";
        } else if (bookingStatus === BookingStatusEnum.BookingRejected) {
            return "예약거절";
        } else if (bookingStatus === BookingStatusEnum.Booked) {
            return "예약됨";
        } else if (bookingStatus === BookingStatusEnum.CancelRequested) {
            return "취소신청";
        } else if (bookingStatus === BookingStatusEnum.Cancelled) {
            if (noShow) {
                return "취소완료(노쇼)";
            } else {
                return "취소완료";
            }
        } else if (bookingStatus === BookingStatusEnum.Visited) {
            if (noShow) {
                return "방문완료(노쇼)";
            } else {
                return "방문완료";
            }
        } else if (bookingStatus == BookingStatusEnum.NoShow) {
            return "노쇼";
        } else {
            return "";
        }
    }

    isBookingRequest(bookingStatus:BookingStatusEnum): boolean {
        return bookingStatus === BookingStatusEnum.BookingRequested;
    }

    isBooked(bookingStatus:BookingStatusEnum): boolean {
        return bookingStatus === BookingStatusEnum.Booked;
    }

    isVisited(bookingStatus:BookingStatusEnum): boolean {
        return bookingStatus === BookingStatusEnum.Visited;
    }

    isCancelled(bookingStatus:BookingStatusEnum): boolean {
        return bookingStatus === BookingStatusEnum.Cancelled;
    }
}

export const bookingUtils = new BookingUtils();
