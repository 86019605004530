import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {notificationStore, siteStore, userStore} from "@/store";
import {apiConstants} from "@/api/apiConstants";
import {Endpoint} from "@/globalDefines/generalTypes";
import {tokenUtils} from "@/lib/tokenUtils";
import {RefreshReplyDto} from "@/api/service/dto/user/userApiDto";

export interface ApiResponse<T> {
    code: string;
    message: string
    data: T | null;
}

interface HttpResponse<T> {
    data: ApiResponse<T>
}

// interface HttpGetParams {
//     token: string|null;
//     parameters?: string;
// }

type GenericObject = { [key: string]: any };

class HttpClient {
    async httpGet<T>(endpoint: Endpoint, parameters?: string): Promise<ApiResponse<T>> {
        let token:string|null = null;

        if (endpoint.requiresToken) {
            if ( tokenUtils.accessTokenExpiring()) {
                try {
                    console.log("call refreshTokenAct");
                    token = await this.refreshTokenAct();
                } catch (message) {
                    return Promise.reject(message);
                }
            } else {
                token = userStore._accessToken;
            }
        }

        return this.httpGetAct(endpoint, token, parameters);
    }

    private async httpGetAct<T>(endpoint: Endpoint, token:string|null, parameters?:string): Promise<ApiResponse<T>> {
        const options: AxiosRequestConfig = {
            headers: {}
        }

        if (endpoint.requiresToken) {
            options.headers.Authorization = ' Bearer ' + token;
        }

        const siteName = this.getSiteName();

        let url = apiConstants.apiServerURL + endpoint.endpoint.replace('{site_name}', siteName);
        if (parameters) {
            url = url + '?' + parameters;
        }

        notificationStore.updateLoading(true);

        try {
            const response: HttpResponse<T> = await axios.get(url, options);
            notificationStore.updateLoading(false);

            if (response.data.code === 'OK') {
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(new Error(response.data.message));
            }
        } catch (e) {
            notificationStore.updateLoading(false);

            const error = e as AxiosError;

            if (error.response) {
                return Promise.reject(new Error(error.response.data.message));
            } else {
                return Promise.reject(new Error('요청이 실패하였습니다.'));
            }
        }
    }

    async httpPost<T>(endpoint: Endpoint, payload: GenericObject): Promise<ApiResponse<T>> {
        let token:string|null = null;

        if (endpoint.requiresToken) {
            if (tokenUtils.tokenExpiring(userStore.accessExpiresAt)) {
                console.log("access token expiring");
                try {
                    token = await this.refreshTokenAct();
                } catch (message) {
                    return Promise.reject(message);
                }
            } else {
                token = userStore.accessToken;
            }
        }

        return this.httpPostAct(endpoint, token, payload);
    }

    private async httpPostAct<T>(endpoint: Endpoint, token:string|null, payload: GenericObject): Promise<ApiResponse<T>> {
        const options: AxiosRequestConfig = {
            headers: {}
        }

        if (endpoint.requiresToken) {
            options.headers.Authorization = ' Bearer ' + token;
        }

        const siteName = this.getSiteName();

        const url = apiConstants.apiServerURL + endpoint.endpoint.replace('{site_name}', siteName);

        notificationStore.updateLoading(true);

        try {
            const response: HttpResponse<T> = await axios.post(url, payload, options);
            notificationStore.setLoading(false);

            if (response.data.code === 'OK') {
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(new Error(response.data.message));
            }
        } catch (e) {
            notificationStore.setLoading(false);

            const error = e as AxiosError;

            if (error.response) {
                console.log(error);

                return Promise.reject(new Error(error.response.data.message))
            } else {
                return Promise.reject(new Error('요청이 실패하였습니다.'));
            }
        }
    }

    async refreshTokenAct(): Promise<string> {
        if (tokenUtils.refreshTokenExpiring()) {
            console.log("refresh token expiring");
            userStore.updateShouldSignInAgain(true);
            return Promise.reject(new Error("리프레시 토큰 유효기간이 만료되었습니다."));
        } else {
            try {
                const apiResponse: ApiResponse<RefreshReplyDto> = await this.httpPostAct(apiConstants.auth.refresh, null, { refreshToken: userStore.refreshToken});

                if (apiResponse.data?.access?.token) {
                    userStore.refreshAccessToken({staySignedIn: userStore.staySignedIn, userToken: apiResponse.data});
                    return Promise.resolve(apiResponse.data.access.token);
                } else {
                    return Promise.reject("리프레시 토큰 API 호출 응답에 토큰이 없습니다.");
                }
            } catch (message) {
                return Promise.reject(message);
            }
        }
    }

    private getSiteName(): string {
        const siteName = siteStore.siteName;
        if (siteName) {
            return siteName;
        } else {
            return 'tablenjoy';
        }
    }
}

export const httpClient = new HttpClient();
