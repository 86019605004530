import {BookingCommonDto} from "@/api/service/dto/order/bookingCommonDto";

// export interface BookingProps {
//     bookingNo: string;
//     bookingStatus: string;
//     freeBooking: boolean;
//
//     brandName: string;
//     storeName: string;
//
//     visitorName: string;
//     visitorPhone: string;
//     visitorEmail: string;
//     visitorRequests?: string;
//
//     bookingRequestDate?: string;
//     bookingCancelledDate?: string;
//     reservationDate?: string;
//     visitDate?: string;
//
//     itemCount: number;
//     adultHeadcount: number;
//     childHeadcount: number;
//     babyHeadcount: number;
//
//     noShow?:boolean;
// }

export function bookingHeadcountStr(booking:BookingCommonDto): string {
    let headcountStr = "";

    if (booking.adultHeadcount > 0) {
        headcountStr = `성인 ${booking.adultHeadcount}명`;
    }

    if (booking.childHeadcount > 0) {
        if (headcountStr.length > 0) {
            headcountStr += ", ";
        }

        headcountStr += `소인 ${booking.childHeadcount}명`
    }

    if (booking.babyHeadcount > 0) {
        if (headcountStr.length > 0) {
            headcountStr += ", ";
        }

        headcountStr += `유아 ${booking.babyHeadcount}명`
    }

    return headcountStr;
}
