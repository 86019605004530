
import {Component, Emit, Vue} from "vue-property-decorator";
import QrScan from "@/views/qrscan/scan/QrScan.vue";
import CouponRow from "@/components/coupon/CouponRow.vue";
import CouponInfo from "@/components/coupon/CouponInfo.vue";
import {couponUtils} from "@/lib/couponUtils";
import {couponApi} from "@/api/service/couponApi";
import {QrProcessedItemProps} from "@/views/qrscan/processed/QrProcessedItemProps";
import QrProcessedItems from "@/views/qrscan/processed/QrProcessedItems.vue";
import {CouponProps} from "@/components/coupon/type/CouponProps";
import MessageSnackbar, {SnackbarMessageType} from "@/components/common/MessageSnackbar.vue";
import MoneyInput from "@/components/common/MoneyInput.vue";
import {CouponListKeywordTypeEnum} from "@/api/service/dto/order/couponListDto";


@Component({
  components: {MoneyInput, MessageSnackbar, QrProcessedItems, CouponInfo, CouponRow, QrScan}
})
export default class QrScanListDialog extends Vue {
  // @Prop() bootingQrScan!: boolean;

  readonly alwaysShow = true;

  private startQrcodeStream = true;

  private searchedCoupon: CouponProps | null = null;
  private decodedItemNo: string | null = null;

  private errorMessage: string | null = null;
  private showErrorMessage = false;
  private readonly errorMessageType = SnackbarMessageType.Error;

  private useAmount: string | null = null;
  private useAmountErrorMessage: string | null = null;

  private actionCompleted = false;

  private processedItems: QrProcessedItemProps[] = [];

  mounted(): void {
    this.searchedCoupon = null;
    this.errorMessage = null;
    this.startQrcodeStream = true;

    this.useAmount = null;
    this.useAmountErrorMessage = null;
  }

  get runQrcodeStream(): boolean {
    // return this.bootingQrScan && this.startQrcodeStream;
    return this.startQrcodeStream;
  }

  get hasDecodedItemNo(): boolean {
    return this.decodedItemNo != null;
  }

  get hasSearchedCoupon(): boolean {
    return this.searchedCoupon != null;
  }

  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.searchedCoupon?.goodsType);
  }

  get couponErrorMessage(): string | null {
    if (this.searchedCoupon == null) {
      return null;
    } else if (couponUtils.isConsumedCoupon(this.searchedCoupon.itemStatus)) {
      return "사용이 완료된 쿠폰입니다."
    } else if (!couponUtils.isAvailableCoupon(this.searchedCoupon.itemStatus)) {
      return "사용할 수 없는 쿠폰입니다.";
    } else {
      return null;
    }
  }

  @Emit()
  onClose(): void {
    this.startQrcodeStream = false;
    return;
  }

  onQrcodeDecoded(decodedString: string): void {
    this.decodedItemNo = decodedString;
    this.startQrcodeStream = false;
    this.actionCompleted = false;

    this.searchCoupon();
  }

  onClickStartScan(): void {
    this.searchedCoupon = null;
    this.startQrcodeStream = true;
    this.errorMessage = null;
  }

  onClickStopScan(): void {
    this.startQrcodeStream = false;
  }

  private async searchCoupon(): Promise<void> {
    this.errorMessage = null;

    if (this.decodedItemNo) {
      try {
        const response = await couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.decodedItemNo);
        this.searchedCoupon = couponUtils.findMatchingCoupon(response.data, this.decodedItemNo);
        if (this.searchedCoupon == null) {
          this.toastErrorMessage("없는 쿠폰이거나 현재 매장에서 사용할 수 없는 쿠폰입니다.");
        }
      } catch (e) {
        const error = e as Error;
        this.toastErrorMessage(error.message);
      }

      // couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.decodedItemNo)
      //     .then(apiResponse => {
      //       console.log(apiResponse);
      //
      //       this.searchedCoupon = couponUtils.findMatchingCoupon(apiResponse.data, this.decodedItemNo);
      //       if (this.searchedCoupon == null) {
      //         this.toastErrorMessage("없는 쿠폰이거나 현재 매장에서 사용할 수 없는 쿠폰입니다.");
      //       }
      //     })
      //     .catch(errorMessage => {
      //       console.log(errorMessage);
      //
      //       this.toastErrorMessage(errorMessage);
      //     });
    }
  }

  private clearSearchedCoupon(): void {
    this.decodedItemNo = null;
    this.searchedCoupon = null;
  }

  onClickUseCoupon(): void {
    if (couponUtils.isMobileCoupon(this.searchedCoupon?.goodsType)) {
      this.useMobileCoupon();
    } else if (couponUtils.isMoneyCoupon(this.searchedCoupon?.goodsType)) {
      this.useMoneyCoupon();
    } else {
      alert('처리할 수 없는 상품 타입입니다.');
    }
  }

  async useMobileCoupon(): Promise<void> {
    if (this.searchedCoupon) {
      try {
        await couponApi.useMobileCoupon(this.searchedCoupon.orderNo, this.searchedCoupon.itemNo);

        this.processedItems.unshift(new QrProcessedItemProps(this.searchedCoupon as CouponProps, null))

        this.actionCompleted = true;
        this.clearSearchedCoupon();
      } catch (e) {
        const error = e as Error;
        this.toastErrorMessage(error.message);
      }

      // couponApi.useMobileCoupon(this.searchedCoupon.orderNo, this.searchedCoupon.itemNo)
      //     .then(apiResponse => {
      //       this.processedItems.unshift(new QrProcessedItemProps(this.searchedCoupon as CouponProps, null))
      //
      //       this.actionCompleted = true;
      //       this.clearSearchedCoupon();
      //     })
      //     .catch(message => {
      //       this.toastErrorMessage(message);
      //     })
    } else {
      this.errorMessage = "조회된 쿠폰이 없습니다.";
    }
  }

  async useMoneyCoupon(): Promise<void> {
    if (!this.useAmount) {
      this.useAmountErrorMessage = "사용금액을 입력하세요.";
      return;
    }

    if (this.searchedCoupon) {
      try {
        await couponApi.useMoneyCoupon(this.searchedCoupon.orderNo, this.searchedCoupon.itemNo, this.useAmount || "");

        this.processedItems.unshift(new QrProcessedItemProps(this.searchedCoupon as CouponProps, this.useAmount))

        this.useAmount = null;
        this.actionCompleted = true;
        this.clearSearchedCoupon();
      } catch (e) {
        const error = e as Error;
        this.toastErrorMessage(error.message);
      }

      // couponApi.useMoneyCoupon(this.searchedCoupon.orderNo, this.searchedCoupon.itemNo, this.useAmount || "")
      //     .then(apiResponse => {
      //       this.processedItems.unshift(new QrProcessedItemProps(this.searchedCoupon as CouponProps, this.useAmount))
      //
      //       this.useAmount = null;
      //       this.actionCompleted = true;
      //       this.clearSearchedCoupon();
      //     })
      //     .catch(message => {
      //       this.toastErrorMessage(message);
      //     });
    } else {
      this.errorMessage = "조회된 쿠폰이 없습니다.";
    }
  }

  private onAmountChanged(amount: string | null) {
    this.useAmount = amount;
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
}

