
import {Component, Emit, Vue} from "vue-property-decorator";

@Component
export default class QrcodeStreamWrapper extends Vue {
  @Emit()
  decode(decodedString:string):string {
    return decodedString;
  }
}
