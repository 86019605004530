
import {Component, Prop, PropSync, Vue} from "vue-property-decorator";

export enum SnackbarMessageType {
  Info = "info", Success = "success", Warning = "warning", Error = "error"
}

@Component
export default class MessageSnackbar extends Vue {
  @Prop() message!:string;
  @Prop() messageType!:SnackbarMessageType;
  @PropSync('showMessage', {type:Boolean}) syncShowMessage!:boolean;
}
