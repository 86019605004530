import {GoodsTypeEnum} from "@/api/service/type/goodsTypeEnum";
import {AgeGroupEnum} from "@/api/service/type/ageGroupEnum";
import {BookingCommonDto} from "@/api/service/dto/order/bookingCommonDto";

export enum BookingDateTypeEnum {
    RequestTime = 'RequestTime',
    VisitDate = 'VisitDate'
}

export enum BookingListKeywordTypeEnum {
    NoKeyword = 'NoKeyword',
    BookingNo = 'BookingNo',
    PhoneNumber = 'PhoneNumber',
    PhoneNumber4 = 'PhoneNumber4'
}

export interface BookingListRequestDto {
    dateType: BookingDateTypeEnum;
    dateFrom: string;
    dateTo: string;
    keywordType: BookingListKeywordTypeEnum;
    keyword: string;
}

export interface BookingInfoItemDto {
    goodsType: GoodsTypeEnum;
    goodsId: number;
    goodsName: string;
    optionId: number;
    optionName: string;
    ageGroup: AgeGroupEnum;
    headcount: number;
}

export interface BookingInfoDto extends BookingCommonDto {
    cancellationFeeRate: string;    // % 단위
    orderItems: BookingInfoItemDto[];
}
