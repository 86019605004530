import {LocalDate, Period, YearMonth} from "js-joda";

class DateUtils {
    toLocalDate(date:Date): LocalDate {
        return LocalDate.of(date.getFullYear(), date.getMonth()+1, date.getDate());
    }

    toLocalDateFromISOStr(str:string): LocalDate {
        return this.toLocalDate(new Date(str));
    }

    toYearMonth(date:Date): YearMonth {
        return YearMonth.of(date.getFullYear(), date.getMonth()+1);
    }

    toYearMonthISOStr(str:string) {
        return this.toYearMonth(new Date(str));
    }

    todayIsBefore(date:LocalDate): boolean {
        const today = LocalDate.now();
        return today.isBefore(date);
    }

    todayIsBeforeISOStr(str:string): boolean {
        const today = LocalDate.now();
        const date = this.toLocalDateFromISOStr(str);
        return today.isBefore(date);
    }

    todayIsAfter(date:LocalDate): boolean {
        const today = LocalDate.now();
        return today.isAfter(date);
    }

    todayIsAfterISOStr(str:string): boolean {
        const today = LocalDate.now();
        const date = this.toLocalDateFromISOStr(str);
        return today.isAfter(date);
    }

    dateDifferenceBetween(date1:LocalDate, date2:LocalDate):number {
        return Period.between(date1, date2).days();
    }

    dateDifferenceBetweenISOStr(str1:string, str2:string):number {
        const date1 = this.toLocalDateFromISOStr(str1);
        const date2 = this.toLocalDateFromISOStr(str2);

        return this.dateDifferenceBetween(date1, date2);
    }

    monthDifferenceBetween(day1:LocalDate, day2:LocalDate):number {
        const startDayOfMonth1 = day1.withDayOfMonth(1);
        const startDayOfMonth2 = day2.withDayOfMonth(15);

        return Period.between(startDayOfMonth1, startDayOfMonth2).months();
    }

    monthDifferenceBetweenISOStr(str1:string, str2:string):number {
        const date1 = this.toLocalDateFromISOStr(str1);
        const date2 = this.toLocalDateFromISOStr(str2);

        return this.monthDifferenceBetween(date1, date2);
    }

    formatDate(dateStr:string|null|undefined):string {
        if (dateStr) {
            return this.toDateString(new Date(dateStr));
        } else {
            return "";
        }
    }

    formatTime(dateStr:string|null|undefined):string {
        if (dateStr) {
            return this.toTimeString(new Date(dateStr));
        } else {
            return "";
        }
    }

    formatDateTime(dateStr:string|null|undefined):string {
        if (dateStr) {
            const date = new Date(dateStr);
            return this.toDateString(date) + " " + this.toTimeString(date);
        } else {
            return "";
        }
    }

    formatToday():string {
        return this.toDateString(new Date());
    }

    toDateString(date:Date):string {
        return `${date.getFullYear()}-${this.zeroFilled(date.getMonth()+1)}-${this.zeroFilled(date.getDate())}`
    }

    toTimeString(date:Date):string {
        return `${this.zeroFilled(date.getHours())}:${this.zeroFilled(date.getMinutes())}`;
    }

    private zeroFilled(num:number):string {
        return ('0000' + num.toString()).slice(-2);
    }
}

export const dateUtils = new DateUtils();
