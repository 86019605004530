
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import QrcodeStreamWrapper from "@/views/qrscan/scan/QrcodeStreamWrapper.vue";

@Component
export default class QrScan extends Vue {
  @Prop() runQrcodeStream!:boolean;

  get qrCodeStream(): Vue.Component|null {
    if (this.runQrcodeStream) {
      return QrcodeStreamWrapper;
    } else {
      return null;
    }
  }

  async onInitQrcodeStream(promise: Promise<any>) {
    console.log('initQrcodeStream2');

    try {
      const { capabilities } = await promise

      // successfully initialized
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        // user denied camera access permission
        alert('카메라 사용 권한이 없습니다.');
      } else if (error.name === 'NotFoundError') {
        // no suitable camera device installed
        alert('카메라를 찾을 수 없습니다.')
      } else if (error.name === 'NotSupportedError') {
        // page is not served over HTTPS (or localhost)
      } else if (error.name === 'NotReadableError') {
        // maybe camera is already in use
        alert('다른 앱이 이미 카메라를 사용중입니다.')
      } else if (error.name === 'OverconstrainedError') {
        // did you requested the front camera although there is none?
      } else if (error.name === 'StreamApiNotSupportedError') {
        // browser seems to be lacking features
        alert('브라우저가 스트림을 지원하지 않습니다.')
      }
    } finally {
      // hide loading indicator
    }
  }

  onDecode(decodedString:string): void {
    // alert(decodedString);
    this.qrcodeDecoded(decodedString);
  }

  @Emit()
  qrcodeDecoded(decodedString:string):string {
    return decodedString;
  }
}

