import {ApiResponse, httpClient} from "@/api/httpClient";
import {apiConstants} from "@/api/apiConstants";
import {BrandSiteListDto} from "@/api/service/dto/brand/brandSiteListDto";
import {BrandInfoDto} from "@/api/service/dto/brand/brandInfoDto";
import {StoreInfoDto} from "@/api/service/dto/brand/storeInfoDto";

class BrandApi {
    queryBrandList(domain:string): Promise<ApiResponse<BrandSiteListDto>> {
        return httpClient.httpGet(apiConstants.brand.brandSiteList,
            "domain=" + domain
        );
    }

    queryBrandInfo(): Promise<ApiResponse<BrandInfoDto>> {
        return httpClient.httpGet(apiConstants.brand.brandSiteInfo);
    }

    queryStoreInfo(): Promise<ApiResponse<StoreInfoDto>> {
        return httpClient.httpGet(apiConstants.shop.basicInfo);
    }
}

export const brandApi = new BrandApi();
