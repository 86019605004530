class PhoneUtils {
    formatPhoneNumber(phone?: string): string {
        if (!phone) {
            return "";
        }

        let formatNum = '';
        if (phone.length == 11) {
            formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (phone.length == 8) {
            formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else {
            if (phone.indexOf('02') == 0) {
                formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            } else {
                formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
        }

        return formatNum;
    }
}

export const phoneUtils = new PhoneUtils();
