class StringUtils {
    isEmpty(str:string|null|undefined) {
        return (!str || str.length === 0 );
    }

    isBlank(str:string|null|undefined) {
        return (!str || /^\s*$/.test(str));
    }

    validateEmail(email:string):boolean {
        const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    }
}

export const stringUtils = new StringUtils();
