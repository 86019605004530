
import {Component, Emit, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {notificationStore, siteStore} from "@/store";
import {brandApi} from "@/api/service/brandApi";


@Component
export default class TopToolbar extends Vue {
  @Getter('title', {namespace: 'site'}) title!: string;

  showMenu = false;
  storeName: string | null = null;

  get brandName(): string | null {
    return siteStore.brandName;
  }

  get loading(): boolean {
    return notificationStore.loading;
  }

  get logoUrl(): string | null {
    return siteStore.logoUrl;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  @Emit()
  editProfile(): void {
    this.toggleMenu();
  }

  @Emit()
  changePassword(): void {
    this.toggleMenu();
  }

  @Emit()
  logout(): void {
    this.toggleMenu();
  }

  @Emit()
  changeBrand(): void {
    this.toggleMenu();
  }

  @Emit()
  reloadPage(): void {
    this.toggleMenu();
  }

  @Emit()
  openQrScan(): void {
    console.log('QR')
    return;
  }

  @Watch('showMenu')
  showMenuChanged(val: boolean, oldVal: boolean): void {
    if (val) {
      brandApi.queryStoreInfo()
          .then(apiResponse => {
            this.storeName = apiResponse.data?.basicInfo?.storeName ?? null;
          })
          .catch(errorMessage => {
            console.log(errorMessage);
          })
    }
  }
}
