
import {Component, Emit, Vue} from "vue-property-decorator";
import SelectSite from "./SelectSite.vue";

@Component({
  components: {SelectSite}
})
export default class SelectSiteDialog extends Vue {
  readonly alwaysShow = true;

  @Emit()
  cancelled(): void {
    return;
  }

  @Emit()
  selected(siteName:string|null): string|null {
    return siteName;
  }

  onBrandSelected(siteName:string|null):void {
    this.selected(siteName);
  }
}

