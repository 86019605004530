import Vue from 'vue';
import Router from 'vue-router';
import store, {siteStore} from '../store';

import CouponList from "@/views/coupon/CouponList.vue";
import BookingList from "@/views/booking/visit/BookingList.vue";
import BookingRequestList from "@/views/booking/approve/BookingRequestList.vue";
import {getModule} from "vuex-module-decorators";
import Site from "@/store/modules/site";
import BookingIndex from "@/views/booking/BookingIndex.vue"
import AppMain from "@/AppMain.vue";
import SelectSite from "@/views/site/SelectSite.vue";

Vue.use(Router);

class RouteMeta {
  title: string;

  constructor ({ title }: { title: string }) {
    this.title = title;
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: SelectSite,
      meta: new RouteMeta( { title: '브랜드 선택'})
    },
    {
      path: '/:site_name',
      name: 'main',
      component: AppMain,
      redirect: '/:site_name/use-service',
      children: [
        {
          path: '/:site_name/use-service',
          name: 'use-coupon',
          component: CouponList,
          meta: new RouteMeta({ title: '쿠폰사용처리' })
        },
        {
          path: '/:site_name/booking',
          name: 'booking',
          component: BookingIndex,
          redirect: '/:site_name/booking/visit-store',
          meta: new RouteMeta({ title: '예약' }),
          children: [
            {
              path: '/:site_name/booking/visit-store',
              name: 'visit-store',
              component: BookingList,
              meta: new RouteMeta({ title: '예약방문처리'})
            },
            {
              path: '/:site_name/booking/approve-booking',
              name: 'approve-booking',
              component: BookingRequestList,
              meta: new RouteMeta({ title: '예약신청승인'})
            }
          ]
        }
      ]
    },
  ]
});

// This callback runs before every route change, including on initial load
router.beforeEach((to, from, next) => {
  const routeMeta = to.meta as RouteMeta;
  const siteName = to.params.site_name;

  console.log(`router: ${siteName}`);

  if (!siteName) {
    const storedSiteName = siteStore.siteName;
    if (storedSiteName) {
      next(`/${storedSiteName}`)
      return;
    }
  }

  siteStore.updateSiteName(siteName);
  siteStore.updateTitle(routeMeta.title);

  next();
});

export default router;
