import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'notification' })
export default class Notification extends VuexModule {
    private _loading = false;

    get loading(): boolean {
        return this._loading;
    }

    @Mutation
    public setLoading(loading: boolean): void {
        this._loading = loading;
    }

    @Action
    public updateLoading(loading: boolean): void {
        this.context.commit('setLoading', loading);
    }
}

