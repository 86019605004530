import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'

import User from '@/store/modules/user'
import Site from "@/store/modules/site";
import Notification from "@/store/modules/notification";
import {getModule} from "vuex-module-decorators";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user: User,
    site: Site,
    notification: Notification
  }
});

export const userStore:User = getModule(User, store);
export const siteStore:Site = getModule(Site, store);
export const notificationStore:Notification = getModule(Notification, store);

export default store;
