
import {Component, Emit, Vue} from "vue-property-decorator";
import {userApi} from "@/api/service/userApi";
import {userStore} from "@/store";
import {stringUtils} from "@/lib/stringUtils";
import {UserRoleEnum} from "@/api/service/type/userRoleEnum";

@Component
export default class UserLogin extends Vue {
  username = "";
  password = "";

  staySignedIn = false;

  usernameErrorMessage:string|null = null;
  passwordErrorMessage:string|null = null;

  // errorMessage:string|null = null;

  e1 = false;
  formValid = false;

  async login(): Promise<void> {
    if (!this.validateInput()) {
      return;
    }

    try {
      const response = await userApi.login(this.username, this.password);
      const loginReply = response.data;

      if (loginReply) {
        if( loginReply.userRole ===  UserRoleEnum.STORE_MANAGER || loginReply.userRole === UserRoleEnum.STORE_OWNER) {
          await userStore.updateStaySignedIn(this.staySignedIn);
          await userStore.updateUserAuth({staySignedIn: this.staySignedIn, userToken: loginReply});
          console.log("login: " + this.staySignedIn)
        } else {
          this.onError("매장 관리자만 이용할 수 있습니다.");
        }
      } else {
        this.onError('로그인 응답에 토큰 정보가 없습니다.');
      }
    } catch (e) {
      const error = e as Error;
      this.onError(error.message);
    }

    // userApi.login(this.username, this.password)
    //     .then(async apiResponse => {
    //       console.log(apiResponse);
    //
    //       if( apiResponse.data?.userRole === 'STORE_MANAGER' || apiResponse.data?.userRole === 'STORE_OWNER') {
    //         await userStore.updateStaySignedIn(this.staySignedIn);
    //         await userStore.updateUserAuth({staySignedIn: this.staySignedIn, userToken: apiResponse.data});
    //         console.log("login: " + this.staySignedIn)
    //       } else {
    //         this.onError("매장 관리자만 이용할 수 있습니다.");
    //       }
    //     })
    //     .catch(message => {
    //       this.onError(message);
    //     });
  }

  @Emit()
  otpLogin():void {
    console.log("onClickFindPassword");
    return;
  }

  @Emit()
  selectSite():void {
    return;
  }

  @Emit()
  onError(message:string):string {
    return message;
  }

  private validateInput(): boolean {
    let valid = true;

    if (stringUtils.isBlank(this.username)) {
      this.usernameErrorMessage = "이름을 입력하세요."
      valid = false;
    }

    if (stringUtils.isBlank(this.password)) {
      this.passwordErrorMessage = "비밀번호를 입력하세요."
      valid = false;
    }

    return valid;
  }
}

