import {CouponProps} from "@/components/coupon/type/CouponProps";
import {GoodsTypeEnum} from "@/api/service/type/goodsTypeEnum";

export class QrProcessedItemProps {
    itemNo:string;
    goodsType:GoodsTypeEnum;
    goodsName:string;
    optionName:string;
    usedBy:string;
    usedAmount:string|null;

    constructor(coupon: CouponProps, usedAmount:string|null) {
        this.itemNo = coupon.itemNo;
        this.goodsType = coupon.goodsType;
        this.goodsName = coupon.goodsName;
        this.optionName = coupon.optionName;
        this.usedBy = coupon.receiverName + ' ' + coupon.receiverPhone;
        this.usedAmount = usedAmount;
    }
}
