import {timeUtils} from "@/lib/timeUtils";
import {userStore} from "@/store";

class TokenUtils {
    accessTokenExpired(): boolean {
        if (!userStore.accessToken) {
            return true;
        }

        return this.tokenExpired(userStore.accessExpiresAt);
    }

    accessTokenExpiring(): boolean {
        if (!userStore.accessToken) {
            return true;
        }

        return this.tokenExpiring(userStore.accessExpiresAt);
    }

    refreshTokenExpired(): boolean {
        if (!userStore.refreshToken) {
            return true;
        }

        return this.tokenExpired(userStore.refreshExpiresAt);
    }

    refreshTokenExpiring(): boolean {
        if (!userStore.refreshToken) {
            return true;
        }

        return this.tokenExpiring(userStore.refreshExpiresAt);
    }

    tokenExpired(expiresAt:number|null): boolean {
        return expiresAt == null || expiresAt < timeUtils.nowSeconds();
    }

    tokenExpiredAtDate(expiresAt:number|null, date:Date): boolean {
        return expiresAt == null || expiresAt < timeUtils.toSeconds(date);
    }

    tokenExpiredAtTime(expiresAt:number|null, timeInSeconds:number): boolean {
        return expiresAt == null || expiresAt < timeInSeconds;
    }

    tokenExpiring(expiresAt:number|null): boolean {
        return expiresAt == null || (expiresAt - 180) < timeUtils.nowSeconds();
    }

    toExpiresAt(expiresIn:number|null|undefined): number|null {
        if (expiresIn != null) {
            return expiresIn + timeUtils.nowSeconds();
        } else {
            return null;
        }
    }
}

export const tokenUtils = new TokenUtils();

