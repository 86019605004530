import {ApiResponse, httpClient} from "@/api/httpClient";
import {apiConstants} from "@/api/apiConstants";
import {CouponListKeywordTypeEnum, CouponListRequestDto, OrderInfoDto} from "@/api/service/dto/order/couponListDto";
import {CouponUseInfoDto} from "@/api/service/dto/order/couponUseInfoDto";
import {CurrencyCodeEnum} from "@/api/service/type/currencyCodeEnum";

// export enum CouponKeywordType {
//     PhoneNumber4 = 'PhoneNumber4', PhoneNumber = 'PhoneNumber', ItemNo = 'ItemNo'
// }

class CouponApi {
    searchCoupons(keywordType: CouponListKeywordTypeEnum, keyword: string): Promise<ApiResponse<OrderInfoDto[]>> {
        const request: CouponListRequestDto = {
            dateFrom: null,
            dateTo: null,
            keywordType,
            keyword
        };

        return httpClient.httpPost(apiConstants.coupon.couponList, request);
    }

    queryCouponUseInfo(itemNo: string): Promise<ApiResponse<CouponUseInfoDto>> {
        return httpClient.httpPost(apiConstants.coupon.itemUseInfo, {
            itemNo: itemNo
        });
    }

    useMobileCoupon(orderNo: string, itemNo: string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.coupon.consumeMobileCoupons, {
            orders: [{
                orderNo: orderNo,
                itemNos: [itemNo]
            }]
        });
    }

    useBookingCoupon(orderNo: string, itemNo: string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.coupon.consumeBookingCoupons, {
            orders: [{
                orderNo: orderNo,
                itemNos: [itemNo]
            }]
        });
    }

    useMoneyCoupon(orderNo: string, itemNo: string, useAmount: string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.coupon.useMoneyCoupon, {
            orderNo: orderNo,
            itemNo: itemNo,
            useAmount: useAmount,
            currencyCode: CurrencyCodeEnum.KRW
        });
    }

    cancelUseTransaction(itemNo: string, transactionNo: string): Promise<ApiResponse<void>> {
        return httpClient.httpPost(apiConstants.coupon.cancelItemUseTransaction, {
            transactions: [{
                itemNo: itemNo,
                transactionNo: transactionNo
            }]
        });
    }
}

export const couponApi = new CouponApi();
