
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {dateUtils} from "@/lib/dateUtils";
import {phoneUtils} from "@/lib/phoneUtils";
import {bookingHeadcountStr} from "@/components/booking/type/BookingProps";
import {bookingUtils} from "@/lib/bookingUtils";
import {BookingCommonDto} from "@/api/service/dto/order/bookingCommonDto";

@Component
export default class BookingRow extends Vue {
  @Prop() readonly booking!: BookingCommonDto;

  mounted(): void {
    console.log(this.booking);
  }

  get isBookingRequest(): boolean {
    return bookingUtils.isBookingRequest(this.booking.bookingStatus);
  }

  get isLiveBooking(): boolean {
    return bookingUtils.isBookingRequest(this.booking.bookingStatus) || bookingUtils.isBooked(this.booking.bookingStatus);
  }

  get bookingStatusCaption(): string {
    return bookingUtils.bookingStatusCaption(this.booking.bookingStatus, this.booking.noShow);
  }

  get requestedDateTime(): string {
    return dateUtils.formatDateTime(this.booking.bookingRequestDate);
  }

  get reservationDateTime(): string {
    return dateUtils.formatDateTime(this.booking.reservationDate);
  }

  get visitDateTime(): string {
    return dateUtils.formatDateTime(this.booking.visitDate);
  }

  get visitorPhone(): string {
    return phoneUtils.formatPhoneNumber(this.booking.visitorPhone);
  }

  get headcountStr(): string {
    return bookingHeadcountStr(this.booking);
  }

  get backgroundColor(): string {
    if (bookingUtils.isBookingRequest(this.booking.bookingStatus) ||
        bookingUtils.isBooked(this.booking.bookingStatus)) {
      return "#FFFFFF"
    } else {
      return "#FFF8E1"
    }
  }

  onClick(): void {
    console.log("onClickCoupon");
    this.click();
  }

  @Emit()
  click(): BookingCommonDto {
    return this.booking;
  }

  parseBookingRoot(val){
    let returnMsg = ''
    if(val.b2bChannelCode == 'KAKAOB'){
      returnMsg = '카카오'
    }else if (val.bookingType == 'ManualBooking'){
      returnMsg = '관리자'
    }else if (val.siteName == 'tablenjoy' || !val.siteName ){
      returnMsg = '테이블엔조이'
    }else if (val.siteName != 'tablenjoy'){
      returnMsg = '자체판매'
    }

    return returnMsg
  }
}
