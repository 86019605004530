
import {Component, Emit, Vue} from "vue-property-decorator";
import {stringUtils} from "@/lib/stringUtils";
import {userApi} from "@/api/service/userApi";

@Component
export default class ChangePassword extends Vue {
  oldPassword = "";
  newPassword = "";
  newPasswordCheck = "";

  oldPasswordErrorMessage: string | null = null;
  newPasswordErrorMessage: string | null = null;
  newPasswordCheckErrorMessage: string | null = null;

  // errorMessage:string|null = null;

  formValid = false;

  onClickChange(): void {
    if (this.validateInput()) {
      this.changePassword();
    }
  }

  onClickCancel(): void {
    this.changePasswordDone();
  }

  private async changePassword(): Promise<void> {
    console.log("changePassword");

    try {
      await userApi.changePassword(this.oldPassword, this.newPassword);
      this.changePasswordDone();
    } catch (e) {
      const error = e as Error;
      this.errorMessage(error.message);
    }

    // userApi.changePassword(this.oldPassword, this.newPassword)
    //     .then(apiResponse => {
    //       this.changePasswordDone();
    //     })
    //     .catch(message => {
    //       this.errorMessage(message);
    //     });
  }

  private validateInput(): boolean {
    let valid = true;

    if (stringUtils.isBlank(this.oldPassword)) {
      this.oldPasswordErrorMessage = "기존 비밀번호를 입력하세요."
      valid = false;
    }

    if (stringUtils.isBlank(this.newPassword)) {
      this.newPasswordErrorMessage = "새 비밀번호를 입력하세요."
      valid = false;
    }

    if (stringUtils.isBlank(this.newPasswordCheck)) {
      this.newPasswordCheckErrorMessage = "새 비밀번호 확인을 입력하세요."
      valid = false;
    } else if (!stringUtils.isBlank(this.newPassword) && this.newPassword !== this.newPasswordCheck) {
      this.newPasswordCheckErrorMessage = "새 비밀번호와 비밀번호 확인이 맞지 않습니다."
      valid = false;
    }

    return valid;
  }

  @Emit()
  changePasswordDone(): void {
    return;
  }

  @Emit()
  errorMessage(message:string): string {
    return message;
  }
}
