import {ApiResponse, httpClient} from "@/api/httpClient";
import {apiConstants} from "@/api/apiConstants";
import {
    ChangeMyInfoDto,
    ChangePasswordDto,
    FindPasswordOtpLoginRequestDto,
    FindPasswordOtpRequestDto,
    LoginReplyDto,
    LoginRequestDto, MyInfoDto
} from "@/api/service/dto/user/userApiDto";

class UserApi {
    login(username:string, password:string): Promise<ApiResponse<LoginReplyDto>> {
        const request:LoginRequestDto = {
          username,
          password
        };

        return httpClient.httpPost(apiConstants.auth.login, request);
    }

    requestOtp(username:string, phoneNumber:string): Promise<ApiResponse<void>> {
        const request: FindPasswordOtpRequestDto = {
            username,
            phoneNumber
        };

        return httpClient.httpPost(apiConstants.user.findPassOtp, request);
    }

    otpLogin(username:string, otp:string): Promise<ApiResponse<LoginReplyDto>> {
        const request: FindPasswordOtpLoginRequestDto = {
            username,
            otp
        };

        return httpClient.httpPost(apiConstants.user.findPassLogin, request);
    }

    changePassword(oldPassword:string, newPassword:string): Promise<ApiResponse<void>> {
        const request: ChangePasswordDto = {
            oldPassword,
            newPassword
        };

        return httpClient.httpPost(apiConstants.user.changePassword, request);
    }

    editProfile(fullName:string, email:string, phoneNumber:string): Promise<ApiResponse<void>> {
        const request: ChangeMyInfoDto = {
            fullName,
            email,
            phoneNumber
        };

        return httpClient.httpPost(apiConstants.user.changeInfo, request);
    }

    queryMyProfile(): Promise<ApiResponse<MyInfoDto>> {
        return httpClient.httpGet(apiConstants.user.myInfo);
    }
}

export const userApi = new UserApi();
