export enum UserRoleEnum {
    TEMP_USER = 'TEMP_USER',
    B2B_USER = 'B2B_USER',
    USER = 'USER',
    STORE_MANAGER = 'STORE_MANAGER',
    STORE_OWNER = 'STORE_OWNER',
    BRAND_MANAGER = 'BRAND_MANAGER',
    BRAND_ADMIN = 'BRAND_ADMIN',
    AGENT = 'AGENT',
    SALES = 'SALES',
    MANAGER = 'MANAGER',
    ADMIN = 'ADMIN'
}
