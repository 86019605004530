import {GoodsTypeEnum} from "@/api/service/type/goodsTypeEnum";
import {OrderItemStatusEnum} from "@/api/service/type/orderItemStatusEnum";
import {OrderStatusEnum} from "@/api/service/type/orderStatusEnum";

export enum CouponListKeywordTypeEnum {
    NoKeyword = 'NoKeyword',
    ItemNo = 'ItemNo',
    PhoneNumber = 'PhoneNumber',
    PhoneNumber4 = 'PhoneNumber4'
}

export interface CouponListRequestDto {
    dateFrom: string|null;
    dateTo: string|null;
    keywordType: CouponListKeywordTypeEnum;
    keyword: string|null;
}

export interface OrderItemDto {
    itemNo: string;
    itemStatus: OrderItemStatusEnum;
    goodsType: GoodsTypeEnum;
    goodsId: number;
    goodsName: string;
    goodsTag: string;
    optionId: number;
    optionName: string;
    issueDate: string;  // yyyy-MM-ddTHH:mm:ss
    availableFrom: string;  // yyyy-MM-dd
    availableTo: string;    // yyyy-MM-dd
    cancellable: boolean;
    cancellableTo: string;  // yyyy-MM-dd
    receiverName: string;
    receiverPhone: string;
    consumedDate: string;   // yyyy-MM-ddTHH:mm:ss
    usableAmount: string;
}

export interface OrderInfoDto {
    orderNo: string;
    username: string;
    buyerName: string;
    buyerPhone: string;
    purchaseDate: string;   // yyyy-MM-ddTHH:mm:ss
    orderStatus: OrderStatusEnum;
    memo: string;
    orderItems: OrderItemDto[];
}
