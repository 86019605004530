
import {Component, Emit, Vue} from "vue-property-decorator";
import {brandApi} from "@/api/service/brandApi";
import {siteStore, userStore} from "@/store";
import MessageSnackbar, {SnackbarMessageType} from "@/components/common/MessageSnackbar.vue";
import {BrandSiteInfoDto} from "@/api/service/dto/brand/brandSiteListDto";

@Component({
  components: {MessageSnackbar}
})
export default class SelectSite extends Vue {
  domain = "";
  brandList: BrandSiteInfoDto[] = [];

  domainErrorMessage:string|null = null;

  errorMessage: string | null = null;
  showErrorMessage = false;
  readonly errorMessageType = SnackbarMessageType.Error;


  async queryBrandList(): Promise<void> {
    this.brandList = [];

    if (!this.domain) {
      this.domainErrorMessage = "도메인을 입력하세요.";
      return;
    }

    try {
      const response = await brandApi.queryBrandList(this.domain);
      this.brandList = response.data?.brands ?? [];
      if (this.brandList.length === 0) {
        this.toastErrorMessage("검색 결과가 없습니다.");
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // brandApi.queryBrandList(this.domain)
    //     .then(apiResponse => {
    //       console.log(apiResponse);
    //       this.brandList = apiResponse.data?.brands ?? [];
    //       if (this.brandList.length === 0) {
    //         this.toastErrorMessage("검색 결과가 없습니다.");
    //       }
    //     })
    //     .catch(errorMessage => {
    //       console.log(errorMessage);
    //       this.toastErrorMessage(errorMessage);
    //     });
  }

  @Emit()
  brandSelected(brand: BrandSiteInfoDto): string | null {
    console.log(brand.siteName, siteStore.siteName);

    // if (brand.siteName == siteStore.siteName) {
    //   this.toastErrorMessage("현재 이용 중인 브랜드와 같은 브랜드입니다.")
    //   return null;
    // }

    siteStore.clearAll();
    userStore.clearAll();

    siteStore.setBrandName(brand.brandName);

    this.$router.push('/' + brand.siteName)
    return brand.siteName;
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
}
