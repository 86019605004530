import {CouponProps, CouponPropsUtil} from "@/components/coupon/type/CouponProps";
import {dateUtils} from "@/lib/dateUtils";
import {OrderInfoDto, OrderItemDto} from "@/api/service/dto/order/couponListDto";
import {OrderItemStatusEnum} from "@/api/service/type/orderItemStatusEnum";
import {GoodsTypeEnum} from "@/api/service/type/goodsTypeEnum";

class CouponUtils {
    isMobileCoupon(goodsType?:GoodsTypeEnum): boolean {
        return goodsType === GoodsTypeEnum.MobileCoupon;
    }

    isMoneyCoupon(goodsType?:GoodsTypeEnum): boolean {
        return goodsType === GoodsTypeEnum.MoneyCoupon;
    }

    isBookingCoupon(goodsType?:GoodsTypeEnum): boolean {
        return goodsType === GoodsTypeEnum.BookingCoupon;
    }

    goodsTypeCaption(goodsType?:GoodsTypeEnum): string {
        if( goodsType === GoodsTypeEnum.MobileCoupon) {
            return '모바일쿠폰';
        } else if (goodsType === GoodsTypeEnum.MoneyCoupon) {
            return '금액권';
        } else if (goodsType === GoodsTypeEnum.BookingCoupon) {
            return '예약권';
        } else if (goodsType === GoodsTypeEnum.Booking) {
            return '예약';
        } else if (goodsType === GoodsTypeEnum.CashCoupon) {
            return '캐시권';
        } else if (goodsType === GoodsTypeEnum.StoreBooking) {
            return '매장예약';
        } else {
            return '';
        }
    }

    couponStatusCaption(coupon:CouponProps): string {
        if (coupon.itemStatus === OrderItemStatusEnum.Available) {
            if( coupon.availableTo != null && dateUtils.todayIsAfterISOStr(coupon.availableTo)) {
                return "유효기간만료";
            } else if( coupon.availableFrom != null && dateUtils.todayIsBeforeISOStr(coupon.availableFrom)) {
                return coupon.availableFrom + " 이후 이용가능";
            } else {
                return "사용가능";
            }
        } else if (coupon.itemStatus === OrderItemStatusEnum.CancelRequested) {
            return "취소요청됨";
        } else if (coupon.itemStatus === OrderItemStatusEnum.Cancelled) {
            return "취소됨";
        } else if (coupon.itemStatus === OrderItemStatusEnum.BookingConnected) {
            return "예약중";
        } else if (coupon.itemStatus === OrderItemStatusEnum.Locked) {
            return "사용불가처리됨";
        } else if (coupon.itemStatus === OrderItemStatusEnum.Consumed) {
            return "사용완료";
        } else {
            return "";
        }
    }

    isConsumedCoupon(itemStatus?:OrderItemStatusEnum): boolean {
        return itemStatus === OrderItemStatusEnum.Consumed;
    }

    isAvailableCoupon(itemStatus?:OrderItemStatusEnum): boolean {
        return itemStatus === OrderItemStatusEnum.Available;
    }

    canUse(coupon:CouponProps): boolean {
        if( coupon.itemStatus !== OrderItemStatusEnum.Available) {
            return false;
        }
        if( coupon.availableTo != null && dateUtils.todayIsAfterISOStr(coupon.availableTo)) {
            return false;
        } else if( coupon.availableFrom != null && dateUtils.todayIsBeforeISOStr(coupon.availableFrom)) {
            return false;
        } else {
            return true;
        }
    }
    canUse5Days(coupon:CouponProps): boolean {
        if( coupon.itemStatus !== OrderItemStatusEnum.Available) {
            return false;
        }
        const tmpDate =  new Date(coupon.availableTo)
        const newDates = dateUtils.toDateString(new Date(tmpDate.setDate(tmpDate.getDate()+5))) ;
        if( coupon.availableTo != null && dateUtils.todayIsAfterISOStr(newDates)) {
            return false;
        }else if( coupon.availableFrom != null && dateUtils.todayIsBeforeISOStr(coupon.availableFrom)) {
            return false;
        } else {
            return true;
        }
    }

    inAvailablePeriod(coupon:CouponProps): boolean {
        if( coupon.availableTo != null && dateUtils.todayIsAfterISOStr(coupon.availableTo)) {
            return false;
        } else if( coupon.availableFrom != null && dateUtils.todayIsBeforeISOStr(coupon.availableFrom)) {
            return false;
        } else {
            return true;
        }
    }

    toCouponProps(orders: OrderInfoDto[] | null | undefined): CouponProps[] {
        if (!orders) {
            return [];
        }

        const couponProps: CouponProps[] = [];

        orders.forEach((order: OrderInfoDto) => {
            const coupons = order.orderItems
                .filter((item: OrderItemDto) => item.itemStatus === OrderItemStatusEnum.Available || item.itemStatus === OrderItemStatusEnum.Consumed)
                .map((item: OrderItemDto) => {
                    return CouponPropsUtil.toCouponPropsFromOrderItemDto(item, order.orderNo);
                });

            couponProps.push(...coupons);
        });

        return couponProps;
    }

    findMatchingCoupon(orders: OrderInfoDto[]|null|undefined, itemNo:string|null|undefined): CouponProps|null {
        if (!itemNo) {
            return null;
        }

        const couponProps = this.toCouponProps(orders);
        if (couponProps.length === 0) {
            return null;
        }

        const index = couponProps.findIndex(coupon => coupon.itemNo === itemNo);
        if (index < 0) {
            return null;
        }

        return couponProps[index];
    }

}

export const couponUtils = new CouponUtils();
