
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {siteStore} from "@/store";
import {brandApi} from "@/api/service/brandApi";
import UserLogin from "@/views/user/components/UserLogin.vue";
import FindPassword from "@/views/user/components/OtpLogin.vue";
import OtpLogin from "@/views/user/components/OtpLogin.vue";
import ChangePassword from "@/views/user/components/ChangePassword.vue";
import {ManageUserAction} from "@/views/user/type/ManageUserAction";
import EditProfile from "@/views/user/components/EditProfile.vue";
import MessageSnackbar, {SnackbarMessageType} from "@/components/common/MessageSnackbar.vue";

@Component({
  components: {OtpLogin, UserLogin, MessageSnackbar}
})
export default class ManageUserDialog extends Vue {
  @Getter('title', {namespace: 'site'}) title!: string;

  @Prop() readonly action!: ManageUserAction;
  // @Prop() readonly refreshWatch!: Date;

  readonly alwaysShow = true;

  findingPassword = false;

  errorMessage: string | null = null;
  showErrorMessage = false;
  errorMessageType = SnackbarMessageType.Error;

  mounted(): void {
    console.log("ManageUser mounted");
    this.updateBrandInfo();
  }

  get brandName(): string {
    const brandName = siteStore.brandName;
    return brandName || "";
  }

  get subComponent(): Vue.Component | null {
    if (this.action == ManageUserAction.Login) {
      return this.findingPassword ? FindPassword : UserLogin;
    } else if (this.action == ManageUserAction.EditProfile) {
      return EditProfile;
    } else if (this.action == ManageUserAction.ChangePassword) {
      return ChangePassword;
    } else {
      return null;
    }
  }

  get slideDirection(): string {
    return this.findingPassword ? "slideLeft" : "slideRight";
  }

  // @Watch("refreshWatch")
  // onRefreshWatchUpdated(val: Date, oldVal: Date): void {
  //   console.log("onRefreshWatchUpdated");
  //   this.updateBrandInfo();
  // }

  private async updateBrandInfo(): Promise<void> {
    console.log('updateBrandInfo');

    try {
      const response = await brandApi.queryBrandInfo();
      const brandInfo = response.data;

      if (brandInfo) {
        siteStore.updateBrandName(brandInfo.basicInfo?.brandName);
        siteStore.updateLogoUrl(brandInfo?.basicInfo?.logoUrl);
      }
    } catch (e) {
      const error = e as Error;

      alert(error.message);
    }

    // brandApi.queryBrandInfo()
    //     .then(apiResponse => {
    //       siteStore.updateBrandName(apiResponse.data?.basicInfo?.brandName);
    //       siteStore.updateLogoUrl(apiResponse.data?.basicInfo?.logoUrl);
    //     })
    //     .catch(errorMessage => {
    //       alert(errorMessage);
    //     })
  }

  private onClickOtpLogin(): void {
    this.findingPassword = true;
  }

  private onClickUserLogin(): void {
    this.findingPassword = false;
  }

  @Emit()
  manageDone(): void {
    return;
  }

  @Emit()
  selectSite(): void {
    return;
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.errorMessageType = SnackbarMessageType.Error;
    this.showErrorMessage = true;
  }
}
