
import {Component, Emit, Prop, PropSync, Vue} from "vue-property-decorator";
import {bookingApi} from "@/api/service/bookingApi";
import MessageSnackbar, {SnackbarMessageType} from "@/components/common/MessageSnackbar.vue";
import BookingVisitInfo from "@/components/booking/BookingVisitInfo.vue";
import {StoreVisitInfoDto} from "@/api/service/dto/order/storeVisitInfoDto";

@Component({
  components: {BookingVisitInfo, MessageSnackbar}
})
export default class CancelVisitDialog extends Vue {
  @Prop() readonly visit!: StoreVisitInfoDto;
  @PropSync('showDialog', {type: Boolean}) syncShowDialog!: boolean;

  errorMessage: string | null = null;
  showErrorMessage = false;
  readonly errorMessageType = SnackbarMessageType.Error;

  get isNoShow(): boolean {
    return this.visit.noShow;
  }

  get cancellable(): boolean {
    return this.visit.cancellable;
  }

  get actionTitle(): string {
    if (this.isNoShow) {
      return "노쇼(방문처리) 완료"
    } else {
      return "방문처리 완료"
    }
  }

  private async cancelVisit(): Promise<void> {
    try {
      await bookingApi.cancelVisit(this.visit.bookingNo, this.visit.transactionNo);
      this.visitCancelled();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // bookingApi.cancelVisit(this.visit.bookingNo, this.visit.transactionNo)
    //     .then(apiResponse => {
    //       this.visitCancelled();
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage);
    //     })
  }

  @Emit()
  private visitCancelled(): void {
    this.closeDialog();
  }

  // private cancelNoShow(): void {
  //   bookingApi.cancelNoShow(this.visit.bookingNo, this.visit.transactionNo)
  //       .then(apiResponse => {
  //         this.noShowCancelled();
  //       })
  //       .catch(errorMessage => {
  //         this.toastErrorMessage(errorMessage);
  //       })
  // }

  @Emit()
  private noShowCancelled(): void {
    this.closeDialog();
  }

  private closeDialog(): void {
    this.syncShowDialog = false;
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
}
