
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {couponUtils} from "@/lib/couponUtils";
import {CouponProps} from "@/components/coupon/type/CouponProps";
import {dateUtils} from "@/lib/dateUtils";
import {numberUtils} from "@/lib/numberUtils";
import {phoneUtils} from "@/lib/phoneUtils";

@Component
export default class CouponRow extends Vue {
  @Prop() readonly coupon!: CouponProps;

  showMenu = false;
  shown = false;
  menuItems: string[] = [];
  readonly emptyMenuItems: string[] = [];
  readonly availableMenuItems = ['쿠폰사용처리', '사용처리취소'];

  mounted() {
    console.log(this.coupon);
  }

  get couponStatusCaption(): string {
    return couponUtils.couponStatusCaption(this.coupon);
  }

  get goodsTypeCaption(): string {
    return couponUtils.goodsTypeCaption(this.coupon.goodsType);
  }

  get isAvailableCoupon(): boolean {
    return couponUtils.isAvailableCoupon(this.coupon.itemStatus);
  }

  get canUse(): boolean {
    return couponUtils.canUse(this.coupon);
  }

  get inAvailablePeriod(): boolean {
    return couponUtils.inAvailablePeriod(this.coupon);
  }

  get isConsumedCoupon(): boolean {
    return couponUtils.isConsumedCoupon(this.coupon.itemStatus);
  }

  get hasUseTransaction(): boolean {
    return this.coupon.consumedDate != undefined;
  }

  get isMobileCoupon(): boolean {
    return couponUtils.isMobileCoupon(this.coupon.goodsType);
  }

  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.coupon.goodsType);
  }

  get isBookingCoupon(): boolean {
    return couponUtils.isBookingCoupon(this.coupon.goodsType);
  }

  get consumedDate(): string {
    return dateUtils.formatDate(this.coupon.consumedDate);
  }

  get usableAmount(): string {
    return numberUtils.formatNumber(this.coupon.usableAmount);
  }

  get hasUsableAmount(): boolean {
    return this.coupon.usableAmount ? parseInt(this.coupon.usableAmount) > 0 : false;
  }

  get receiverPhone(): string {
    return phoneUtils.formatPhoneNumber(this.coupon.receiverPhone);
  }

  get backgroundColor(): string {
    if (this.isAvailableCoupon) {
      return "#FFFFFF"
    } else {
      return "#FFF8E1"
    }
  }

  menuTextColor(index: number): string {
    if (index == 0) {
      return "primary";
    } else {
      return "error";
    }
  }

  onClickCoupon(): void {
    if (this.isAvailableCoupon && this.hasUseTransaction) {
      this.menuItems = this.availableMenuItems;
    } else {
      this.menuItems = this.emptyMenuItems;

      if (this.isAvailableCoupon) {
        this.useCoupon();
      } else {
        this.cancelUseCoupon();
      }
    }

    console.log("onClickCoupon");
  }

  onMenuSelected(index: number): void {
    if (index === 0) {
      this.useCoupon();
    } else {
      this.cancelUseCoupon();
    }
  }

  @Emit()
  useCoupon(): CouponProps {
    return this.coupon;
  }

  @Emit()
  cancelUseCoupon(): CouponProps {
    return this.coupon;
  }
}
