
import {Component, Emit, Vue} from "vue-property-decorator";
import {stringUtils} from "@/lib/stringUtils";
import {userApi} from "@/api/service/userApi";

@Component
export default class EditProfile extends Vue {
  fullName = "";
  email = "";
  phoneNumber = "";

  fullNameErrorMessage: string | null = null;
  emailErrorMessage: string | null = null;
  phoneNumberErrorMessage: string | null = null;

  // errorMessage: string| null = null;

  formValid = true;

  mounted(): void {
    this.queryProfile();
  }

  onClickChange(): void {
    if (this.validateInput()) {
      this.editProfile();
    }
  }

  onClickCancel(): void {
    this.editProfileDone();
  }

  private async editProfile(): Promise<void> {
    console.log("editProfile");

    try {
      await userApi.editProfile(this.fullName, this.email, this.phoneNumber);
      this.editProfileDone();
    } catch (e) {
      const error = e as Error;
      this.onError(error.message);
    }

    // userApi.editProfile(this.fullName, this.email, this.phoneNumber)
    //     .then(apiResponse => {
    //       this.editProfileDone();
    //     })
    //     .catch(message => {
    //       this.onError(message);
    //     });
  }

  private validateInput(): boolean {
    let valid = true;

    if (stringUtils.isBlank(this.fullName)) {
      this.fullNameErrorMessage = "이름을 입력하세요."
      valid = false;
    }

    if (stringUtils.isBlank(this.email)) {
      this.emailErrorMessage = "이메일을 입력하세요."
      valid = false;
    } else if (!stringUtils.validateEmail(this.email)) {
      this.emailErrorMessage = "이메일 형식이 맞지 않습니다."
      valid = false;
    }

    if (stringUtils.isBlank(this.phoneNumber)) {
      this.phoneNumberErrorMessage = "전화번호를 입력하세요.";
      valid = false;
    }

    return valid;
  }

  private async queryProfile(): Promise<void> {
    try {
      const response = await userApi.queryMyProfile();
      const myInfo = response.data;

      this.fullName = myInfo?.fullName || "";
      this.email = myInfo?.email || "";
      this.phoneNumber = myInfo?.phoneNumber || "";
    } catch (e) {
      const error = e as Error;
      this.onError(error.message);
    }

    // userApi.queryMyProfile()
    //     .then(apiResponse => {
    //       this.fullName = apiResponse.data?.fullName || "";
    //       this.email = apiResponse.data?.email || "";
    //       this.phoneNumber = apiResponse.data?.phoneNumber || "";
    //     })
    //     .catch(message => {
    //       this.onError(message);
    //     })
  }

  @Emit()
  editProfileDone(): void {
    return;
  }

  @Emit()
  onError(message:string): string {
    return message;
  }
}
