
import {Component, Vue} from "vue-property-decorator";
import CouponRow from "@/components/coupon/CouponRow.vue";
import UseCouponDialog from "@/views/coupon/components/UseCouponDialog.vue";
import CancelUseCouponDialog from "@/views/coupon/components/CancelUseCouponDialog.vue";
import {CouponProps} from "@/components/coupon/type/CouponProps";
import {couponApi} from "@/api/service/couponApi";
import MessageSnackbar, {SnackbarMessageType} from "@/components/common/MessageSnackbar.vue";
import {CouponTransactionProps, CouponTransactionPropsUtil} from "@/components/coupon/type/CouponTransactionProps";
import {couponUtils} from "@/lib/couponUtils";
import {CouponListKeywordTypeEnum} from "@/api/service/dto/order/couponListDto";
import {CouponUseTransactionDto} from "@/api/service/dto/order/couponUseInfoDto";

@Component({
  components: {CancelUseCouponDialog, UseCouponDialog, CouponRow, MessageSnackbar}
})
export default class CouponList extends Vue {
  private readonly keywordTypes = [
    {text: "전화번호 끝 4자리", value: CouponListKeywordTypeEnum.PhoneNumber4},
    {text: "전화번호", value: CouponListKeywordTypeEnum.PhoneNumber},
    {text: "쿠폰번호", value: CouponListKeywordTypeEnum.ItemNo},
  ];
  private keywordType = CouponListKeywordTypeEnum.PhoneNumber4;
  private keyword: string | null = null;

  private keywordErrorMessage: string | null = null;

  private couponList: CouponProps[] = [];
  private selectedCoupon: CouponProps | null = null;
  private useTransactions: CouponTransactionProps[] = [];

  private shouldShowUseCouponDialog = false;
  private shouldShowCancelUseCouponDialog = false;

  private hasNoCoupon = false;

  errorMessage: string | null = null;
  showErrorMessage = false;
  errorMessageType = SnackbarMessageType.Error;

  mounted(): void {
    console.log('mounted');
  }

  get useCouponDialog(): Vue.Component | null {
    return this.shouldShowUseCouponDialog ? UseCouponDialog : null;
  }

  get cancelUseCouponDialog(): Vue.Component | null {
    return this.shouldShowCancelUseCouponDialog ? CancelUseCouponDialog : null;
  }

  // private refresh(): void {
  //   if (this.keyword) {
  //     this.searchCoupons();
  //   }
  // }

  private async searchCoupons(): Promise<void> {
    this.hasNoCoupon = false;

    if (!this.keyword) {
      this.keywordErrorMessage = "검색어를 입력하세요."
      return;
    }

    this.couponList = [];

    try {
      const response = await couponApi.searchCoupons(this.keywordType, this.keyword);
      this.couponList = couponUtils.toCouponProps(response.data);

      if (this.couponList.length === 0) {
        this.hasNoCoupon = true;
      }

      console.log(this.couponList);
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // couponApi.searchCoupons(this.keywordType, this.keyword)
    //     .then(apiResponse => {
    //       this.couponList = couponUtils.toCouponProps(apiResponse.data);
    //
    //       if (this.couponList.length === 0) {
    //         this.hasNoCoupon = true;
    //       }
    //
    //       console.log(this.couponList);
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Error);
    //     })
  }

  private async refreshSelectedItem(): Promise<void> {
    if (!this.selectedCoupon?.itemNo) {
      return;
    }

    try {
      const response = await couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.selectedCoupon.itemNo);
      let updatedCoupon = couponUtils.findMatchingCoupon(response.data, this.selectedCoupon?.itemNo);
      if (updatedCoupon == null) {
        return;
      }

      const targetIndex = this.couponList.findIndex(coupon => coupon.itemNo === this.selectedCoupon?.itemNo);
      if (targetIndex < 0) {
        return;
      }

      this.couponList[targetIndex] = updatedCoupon;
      // this.couponList.splice(targetIndex, 1, updatedCoupon);
      this.$forceUpdate();
    } catch (e) {
      const error = e as Error;
      console.log(error.message);
    }

    // couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.selectedCoupon.itemNo)
    //     .then(apiResponse => {
    //       let updatedCoupon = couponUtils.findMatchingCoupon(apiResponse.data, this.selectedCoupon?.itemNo);
    //       if (updatedCoupon == null) {
    //         return;
    //       }
    //
    //       const targetIndex = this.couponList.findIndex(coupon => coupon.itemNo === this.selectedCoupon?.itemNo);
    //       if (targetIndex < 0) {
    //         return;
    //       }
    //
    //       this.couponList[targetIndex] = updatedCoupon;
    //       // this.couponList.splice(targetIndex, 1, updatedCoupon);
    //       this.$forceUpdate();
    //     })
    //     .catch(message => {
    //       console.log(message);
    //     })
  }

  // private toCouponProps(orders: GenericObject | null | undefined): CouponProps[] {
  //   if (!orders) {
  //     return [];
  //   }
  //
  //   let couponProps: CouponProps[] = [];
  //
  //   orders.forEach((order: GenericObject) => {
  //     let coupons = order.orderItems
  //         .filter((item: GenericObject) => item.itemStatus === 'Available' || item.itemStatus === 'Consumed')
  //         .map((item: GenericObject) => {
  //           item.orderNo = order.orderNo;
  //           return item;
  //         });
  //
  //     couponProps.push(...coupons);
  //   });
  //
  //   return couponProps;
  // }
  //
  // private findMatchingCoupon(orders: GenericObject|null|undefined, itemNo:string|null|undefined): CouponProps|null {
  //   if (!itemNo) {
  //     return null;
  //   }
  //
  //   let couponProps = this.toCouponProps(orders);
  //   if (couponProps.length === 0) {
  //     return null;
  //   }
  //
  //   const index = couponProps.findIndex(coupon => coupon.itemNo === itemNo);
  //   if (index < 0) {
  //     return null;
  //   }
  //
  //   return couponProps[index];
  // }

  // private onClickCoupon(coupon: CouponProps) {
  //   if (coupon.itemStatus === 'Available') {
  //     this.showUseCouponDialog(coupon);
  //   } else if (coupon.itemStatus === 'Consumed') {
  //     this.showCancelUseCouponDialog(coupon);
  //   }
  // }

  onClickUseCoupon(coupon: CouponProps): void {
    this.showUseCouponDialog(coupon);
  }

  onClickCancelUseCoupon(coupon: CouponProps): void {
    this.showCancelUseCouponDialog(coupon);
  }

  private showUseCouponDialog(coupon: CouponProps): void {
    this.selectedCoupon = coupon;
    this.shouldShowUseCouponDialog = true;
  }

  // private closeUseCouponDialog(shouldRefresh: boolean): void {
  //   this.shouldShowUseCouponDialog = false;
  //
  //   if (shouldRefresh) {
  //     this.refresh();
  //   }
  // }

  private showCancelUseCouponDialog(coupon: CouponProps): void {
    this.queryTransactionsAndShowDialog(coupon);
  }

  private onUseCancelled() {
    // this.refresh(true);
    this.refreshSelectedItem();
    this.toastErrorMessage("사용처리가 취소되었습니다.", SnackbarMessageType.Info)
  }

  private onUseCompleted(): void {
    // this.refresh(true);
    this.refreshSelectedItem();
    this.toastErrorMessage("사용처리 되었습니다.", SnackbarMessageType.Info)
  }

  private async queryTransactionsAndShowDialog(coupon: CouponProps): Promise<void> {
    this.useTransactions = [];

    try {
      const response = await couponApi.queryCouponUseInfo(coupon.itemNo);
      const useInfo = response.data;

      if (useInfo && useInfo.transactions.length > 0) {
        this.useTransactions = useInfo.transactions
            .filter((transaction: CouponUseTransactionDto) => !transaction.cancelled)
            .map((transaction: CouponUseTransactionDto) => {
              return CouponTransactionPropsUtil.toTransactionPropsFromDto(transaction, useInfo.itemNo, useInfo.goodsType);
              // transaction.itemNo = apiResponse.data?.itemNo;
              // transaction.goodsType = apiResponse.data?.goodsType;
              // return transaction;
            })

        this.selectedCoupon = coupon;
        this.shouldShowCancelUseCouponDialog = true;
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // couponApi.queryCouponUseInfo(coupon.itemNo)
    //     .then(apiResponse => {
    //       const useInfo = apiResponse.data;
    //
    //       if (useInfo && useInfo.transactions.length > 0) {
    //         this.useTransactions = useInfo.transactions
    //             .filter((transaction: CouponUseTransactionDto) => !transaction.cancelled)
    //             .map((transaction: CouponUseTransactionDto) => {
    //               return CouponTransactionPropsUtil.toTransactionPropsFromDto(transaction, useInfo.itemNo, useInfo.goodsType);
    //               // transaction.itemNo = apiResponse.data?.itemNo;
    //               // transaction.goodsType = apiResponse.data?.goodsType;
    //               // return transaction;
    //             })
    //
    //         this.selectedCoupon = coupon;
    //         this.shouldShowCancelUseCouponDialog = true;
    //       }
    //     })
    //     .catch(message => {
    //       this.toastErrorMessage(message, SnackbarMessageType.Error);
    //     })
  }

  couponProps(coupon: CouponProps): CouponProps {
    return coupon;
  }

  private toastErrorMessage(message: string, messageType: SnackbarMessageType) {
    this.errorMessage = message;
    this.errorMessageType = messageType;
    this.showErrorMessage = true;
  }
}

