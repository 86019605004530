import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {userStore} from "@/store";
import {tokenUtils} from "@/lib/tokenUtils";
import {LoginReplyDto, RefreshReplyDto} from "@/api/service/dto/user/userApiDto";

export interface StoreUserTokensObjectParam {
    staySignedIn: boolean | null;
    userToken: LoginReplyDto | null;
}

export interface StoreUserAccessTokenObjectParam {
    staySignedIn: boolean | null;
    userToken: RefreshReplyDto | null;
}

export interface StoreUserStringParam {
    staySignedIn: boolean | null;
    value:string | null;
}

export interface StoreUserNumberParam {
    staySignedIn: boolean | null;
    value:number | null;
}

enum UserKey {
    userRole = "userRole",
    accessToken = "accessToken", accessExpiresIn = "accessExpiresIn", accessExpiresAt = 'accessExpiresAt',
    refreshToken = "refreshToken", refreshExpiresIn = "refreshExpiresIn", refreshExpiresAt = "refreshExpiresAt",
    staySignedIn = "staySignedIn"
}

@Module({namespaced: true, name: 'user'})
class User extends VuexModule {
    _userRole: string | null = null;

    _accessToken: string | null = null;
    _accessExpiresIn: number | null = null;
    _accessExpiresAt: number | null = null;

    _refreshToken: string | null = null;
    _refreshExpiresIn: number | null = null;
    _refreshExpiresAt: number | null = null;

    _staySignedIn: boolean | null = null;

    _shouldSignInAgain: boolean | null = null;

    get userRole(): string | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._userRole) {
            return this._userRole;
        } else {
            const userRole = storage.getItem(UserKey.userRole);
            if (userRole) {
                userStore.updateUserRole({staySignedIn: staySignedIn, value: userRole});
            }
            return userRole;
        }
    }

    @Mutation
    setUserRole(param: StoreUserStringParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._userRole = param.value;
        if (this._userRole) {
            storage.setItem(UserKey.userRole, this._userRole);
        } else {
            storage.removeItem(UserKey.userRole);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.userRole);
    }

    @Action
    updateUserRole(param: StoreUserStringParam): void {
        this.context.commit('setUserRole', param);
    }

    //
    // Access Token
    //

    get accessToken(): string | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._accessToken) {
            return this._accessToken;
        } else {
            const accessToken = storage.getItem(UserKey.accessToken);
            if (accessToken) {
                userStore.updateAccessToken({staySignedIn: staySignedIn, value: accessToken});
            }
            return accessToken;
        }
    }

    @Mutation
    setAccessToken(param: StoreUserStringParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._accessToken = param.value;
        if (this._accessToken) {
            this._shouldSignInAgain = false;
            storage.setItem(UserKey.accessToken, this._accessToken);
        } else {
            storage.removeItem(UserKey.accessToken);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.accessToken);
    }

    @Action
    updateAccessToken(param: StoreUserStringParam): void {
        this.context.commit('setAccessToken', param);
    }

    get accessExpiresIn(): number | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._accessExpiresIn) {
            return this._accessExpiresIn;
        } else {
            const accessExpiresInStr = storage.getItem(UserKey.accessExpiresIn);
            if (accessExpiresInStr) {
                const accessExpiresIn = parseInt(accessExpiresInStr);
                userStore.updateAccessExpiresIn({staySignedIn: staySignedIn, value: accessExpiresIn});
                return accessExpiresIn;
            }
            return null;
        }
    }

    @Mutation
    setAccessExpiresIn(param: StoreUserNumberParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._accessExpiresIn = param.value;
        if (this._accessExpiresIn) {
            storage.setItem(UserKey.accessExpiresIn, this._accessExpiresIn.toString());
        } else {
            storage.removeItem(UserKey.accessExpiresIn);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.accessExpiresIn);
    }

    @Action
    updateAccessExpiresIn(param: StoreUserNumberParam): void {
        this.context.commit('setAccessExpiresIn', param);
    }

    get accessExpiresAt(): number | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._accessExpiresAt) {
            console.log("vuex: user", this._accessExpiresAt);
            return this._accessExpiresAt;
        } else {
            const accessExpiresAtStr = storage.getItem(UserKey.accessExpiresAt);
            console.log("vuex: localStorage", accessExpiresAtStr);
            if (accessExpiresAtStr) {
                const accessExpiresAt = parseInt(accessExpiresAtStr);
                userStore.updateAccessExpiresAt({staySignedIn: staySignedIn, value: accessExpiresAt});
                return accessExpiresAt;
            }
            return null;
        }
    }

    @Mutation
    setAccessExpiresAt(param: StoreUserNumberParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._accessExpiresAt = param.value;
        if (this._accessExpiresAt) {
            storage.setItem(UserKey.accessExpiresAt, this._accessExpiresAt.toString());
        } else {
            storage.removeItem(UserKey.accessExpiresAt);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.accessExpiresAt);
    }

    @Action
    updateAccessExpiresAt(param: StoreUserNumberParam): void {
        this.context.commit('setAccessExpiresAt', param);
    }

    //
    // Refresh Token
    //

    get refreshToken(): string | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._refreshToken) {
            return this._refreshToken;
        } else {
            const refreshToken = storage.getItem(UserKey.refreshToken);
            if (refreshToken) {
                userStore.updateRefreshToken({staySignedIn: staySignedIn, value: refreshToken});
            }
            return refreshToken;
        }
    }

    @Mutation
    setRefreshToken(param: StoreUserStringParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._refreshToken = param.value;
        if (this._refreshToken) {
            storage.setItem(UserKey.refreshToken, this._refreshToken);
        } else {
            storage.removeItem(UserKey.refreshToken);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.refreshToken);
    }

    @Action
    updateRefreshToken(param: StoreUserStringParam): void {
        this.context.commit('setRefreshToken', param);
    }

    get refreshExpiresIn(): number | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._refreshExpiresIn) {
            return this._refreshExpiresIn;
        } else {
            const refreshExpiresInStr = storage.getItem(UserKey.refreshExpiresIn);
            if (refreshExpiresInStr) {
                const refreshExpiresIn = parseInt(refreshExpiresInStr);
                userStore.updateRefreshExpiresIn({staySignedIn: staySignedIn, value: refreshExpiresIn});
                return refreshExpiresIn;
            }
            return null;
        }
    }

    @Mutation
    setRefreshExpiresIn(param: StoreUserNumberParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._refreshExpiresIn = param.value;
        if (this._refreshExpiresIn) {
            storage.setItem(UserKey.refreshExpiresIn, this._refreshExpiresIn.toString());
        } else {
            storage.removeItem(UserKey.refreshExpiresIn);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.refreshExpiresIn);
    }

    @Action
    updateRefreshExpiresIn(param: StoreUserNumberParam): void {
        this.context.commit('setRefreshExpiresIn', param);
    }

    get refreshExpiresAt(): number | null {
        const staySignedIn = this.staySignedIn;
        const storage = staySignedIn? localStorage : sessionStorage;

        if (this._refreshExpiresAt) {
            return this._refreshExpiresAt;
        } else {
            const refreshExpiresAtStr = storage.getItem(UserKey.refreshExpiresAt);
            if (refreshExpiresAtStr) {
                const refreshExpiresAt = parseInt(refreshExpiresAtStr);
                userStore.updateRefreshExpiresAt({staySignedIn: staySignedIn, value: refreshExpiresAt});
                return refreshExpiresAt;
            }
            return null;
        }
    }

    @Mutation
    setRefreshExpiresAt(param: StoreUserNumberParam): void {
        const storage = param.staySignedIn? localStorage : sessionStorage;

        this._refreshExpiresAt = param.value;
        if (this._refreshExpiresAt) {
            storage.setItem(UserKey.refreshExpiresAt, this._refreshExpiresAt.toString());
        } else {
            storage.removeItem(UserKey.refreshExpiresAt);
        }

        const storageOp = param.staySignedIn? sessionStorage : localStorage;
        storageOp.removeItem(UserKey.refreshExpiresAt);
    }

    @Action
    updateRefreshExpiresAt(param: StoreUserNumberParam): void {
        this.context.commit('setRefreshExpiresAt', param);
    }

    //
    // Stay signed in
    //
    get staySignedIn(): boolean | null {
        if (this._staySignedIn != null) {
            return this._staySignedIn;
        } else {
            const staySignedIn = localStorage.getItem(UserKey.staySignedIn);
            if (staySignedIn != null) {
                userStore.updateStaySignedIn(staySignedIn === "true");
            }
            return staySignedIn? staySignedIn === "true" : null;
        }
    }

    @Mutation
    setStaySignedIn(staySignedIn: boolean | null): void {
        console.log("vuex: setStaySignedIn: ", staySignedIn)
        this._staySignedIn = staySignedIn;

        if (staySignedIn != null) {
            localStorage.setItem(UserKey.staySignedIn, staySignedIn? "true": "false");
        } else {
            localStorage.removeItem(UserKey.staySignedIn);
        }
    }

    @Action
    updateStaySignedIn(staySignedIn: boolean | null): void {
        this.context.commit('setStaySignedIn', staySignedIn);
    }

    //
    // Should sign in again
    //
    get shouldSignInAgain(): boolean {
        return this._shouldSignInAgain == true;
    }

    @Mutation
    setShouldSignInAgain(shouldSignInAgain: boolean | null): void {
        this._shouldSignInAgain = shouldSignInAgain;
    }

    @Action
    updateShouldSignInAgain(shouldSignInAgain: boolean | null): void {
        this.context.commit('setShouldSignInAgain', shouldSignInAgain);
    }

    //
    //
    //

    @Action
    setUserAuth(param: StoreUserTokensObjectParam): void {
        console.log("vuex: setUserAuth", param.staySignedIn);

        this.setUserRole({staySignedIn: param.staySignedIn,
            value:param.userToken?.userRole ?? null});

        this.setAccessToken({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.access.token ?? null});
        this.setAccessExpiresIn({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.access.expiresIn ?? null});
        this.setAccessExpiresAt({staySignedIn: param.staySignedIn,
            value: tokenUtils.toExpiresAt(param.userToken?.tokens?.access?.expiresIn)});

        this.setRefreshToken({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.refresh.token ?? null});
        this.setRefreshExpiresIn({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.refresh.expiresIn ?? null});
        this.setRefreshExpiresAt({staySignedIn: param.staySignedIn,
            value: tokenUtils.toExpiresAt(param.userToken?.tokens?.refresh?.expiresIn)});
    }

    @Action
    updateUserAuth(param: StoreUserTokensObjectParam): void {
        console.log("vuex: updateUserAuth", param.staySignedIn);

        this.updateUserRole({staySignedIn: param.staySignedIn,
            value:param.userToken?.userRole ?? null});

        this.updateAccessToken({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.access.token ?? null});
        this.updateAccessExpiresIn({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.access.expiresIn ?? null});
        this.updateAccessExpiresAt({staySignedIn: param.staySignedIn,
            value: tokenUtils.toExpiresAt(param.userToken?.tokens?.access?.expiresIn)});

        this.updateRefreshToken({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.refresh.token ?? null});
        this.updateRefreshExpiresIn({staySignedIn: param.staySignedIn,
            value: param.userToken?.tokens.refresh.expiresIn ?? null});
        this.updateRefreshExpiresAt({staySignedIn: param.staySignedIn,
            value: tokenUtils.toExpiresAt(param.userToken?.tokens?.refresh?.expiresIn)});
    }

    @Action
    refreshAccessToken(param: StoreUserAccessTokenObjectParam): void {
        this.updateAccessToken({staySignedIn: param.staySignedIn,
            value: param.userToken?.access.token ?? null});
        this.updateAccessExpiresIn({staySignedIn: param.staySignedIn,
            value: param.userToken?.access.expiresIn ?? null});
        this.updateAccessExpiresAt({staySignedIn: param.staySignedIn,
            value: tokenUtils.toExpiresAt(param.userToken?.access?.expiresIn)});
    }

    @Action
    clearAll(): void {
        this.updateUserAuth({staySignedIn: false, userToken: null});
    }
}

export default User;
