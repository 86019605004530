
import {Component, Prop, Vue} from "vue-property-decorator";
import {QrProcessedItemProps} from "./QrProcessedItemProps";
import {couponUtils} from "@/lib/couponUtils";
import {numberUtils} from "@/lib/numberUtils";

@Component
export default class QrProcessedItems extends Vue {
  @Prop() readonly processedItems!:QrProcessedItemProps[];

  isMobileCoupon(item:QrProcessedItemProps): boolean {
    return couponUtils.isMobileCoupon(item.goodsType);
  }

  isMoneyCoupon(item:QrProcessedItemProps): boolean {
    return couponUtils.isMoneyCoupon(item.goodsType);
  }

  goodsTypeCaption(item:QrProcessedItemProps): string {
    return couponUtils.goodsTypeCaption(item.goodsType);
  }

  usedAmount(item:QrProcessedItemProps): string {
    return numberUtils.formatNumber(item.usedAmount);
  }
}
